import React, { useEffect } from "react";

import { Link, useParams } from "react-router-dom";

import NoImgFound from "../../../assets/No-image-found.jpg"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { stopLoader } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { setTokenHeader } from "../../../helpers/api_helper";
import { editClasses, getClassUsers } from "../../../store/classes/actions";
import moment from "moment";



const UsersInClass = () => {
  const { classUsers, singleClass } = useSelector((state) => state.Classes)
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(stopLoader());
    dispatch(getClassUsers(params.id))
    dispatch(editClasses(params.id));
  }, []);

  useEffect(() => {
    console.log('signle provider : ', singleClass)
  }, [singleClass])
  //meta title
  document.title = "Class Detail";


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Instructor" breadcrumbItem={classUsers?.users?.name} />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">

                </div>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <div className="  mb-4">

                      </div>
                      <h5 className="font-size-15 text-truncate text-center" >
                        {singleClass?.title} Class
                      </h5>
                    </Col>

                    <Col sm={12} className="text-center">
                      {
                        (singleClass?.classImage != null || singleClass?.classImage != '') ?
                          (
                            <img
                              src={`${process.env.REACT_APP_ASSET_URL}${singleClass?.classImage}`}
                              alt=""
                              className="img-thumbnail "
                              style={{ 'maxHeight': '200px' }}
                            />
                          ) : (
                            <img
                              src={NoImgFound}
                              alt=""
                              className="img-thumbnail "
                              style={{ 'maxHeight': '200px' }}

                            />
                          )
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4 h4">
                    Instructor Detail
                  </CardTitle>

                  <p className="text-muted mb-4">
                    {singleClass?.instructorId?.personalDetail}
                  </p>
                  <div className="table-responsive">

                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <td colSpan={2} className="text-center">
                            {
                              (singleClass?.instructorId?.userProfile != null || singleClass?.instructorId?.userProfile != '') ?
                                (
                                  <img
                                    src={`${process.env.REACT_APP_ASSET_URL}${singleClass?.instructorId?.userProfile}`}
                                    alt=""
                                    className="img-thumbnail "
                                    style={{ 'maxHeight': '200px' }}
                                  />
                                ) : (
                                  <img
                                    src={NoImgFound}
                                    alt=""
                                    className="img-thumbnail "
                                    style={{ 'maxHeight': '200px' }}

                                  />
                                )
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{singleClass?.instructorId?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{singleClass?.instructorId?.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{singleClass?.instructorId?.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Location :</th>
                          <td>{singleClass?.instructorId?.location.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Session Rate :</th>
                          <td>${singleClass?.instructorId?.sessionRate}</td>
                        </tr>
                        <tr>
                          <th scope="row">Gender :</th>
                          <td>{(singleClass?.instructorId?.gender == 0) ? 'Male' : 'Female'}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

            </Col>
            <Col xl="8">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Category
                          </p>
                          <h4 className="mb-0">{singleClass?.categoryId?.name}</h4>
                        </div>


                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Location
                          </p>
                          <h4 className="mb-0"> {singleClass?.location?.name}</h4>
                        </div>

                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Total Seats / Joined
                          </p>
                          <h4 className="mb-0 txt-danger">{singleClass?.totalSeat} / {singleClass?.studentJoin}</h4>

                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Date
                          </p>
                          <h4 className="mb-0 txt-danger">{moment(singleClass?.date).format('MM/DD/YYYY')}</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Time
                          </p>
                          <h4 className="mb-0 txt-danger">{moment(singleClass?.startTime).format('hh:mm a').toLocaleString()} - {moment(singleClass?.endTime).format('hh:mm a').toLocaleString()}</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Price
                          </p>
                          <h4 className="mb-0 txt-danger">${singleClass?.price}</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4 h4">Users</CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {classUsers?.data?.map((f, i) => {
                          return (
                            <tr key={i + "-file"}>
                              <th scope="row">i</th>
                              <td>{f?.userId?.fname}</td>
                              <td>{f?.userId?.lname}</td>
                              <td>{f?.userId?.email}</td>
                              <td>{f?.userId?.phone}</td>
                            </tr>
                          )
                        })}


                      </tbody>
                    </Table>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default UsersInClass;
