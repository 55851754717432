/** Get Dashboard Chart data */
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_FAIL = "GET_LOCATIONS_FAIL";

export const ADD_LOCATIONS = "ADD_LOCATIONS";
export const ADD_LOCATIONS_SUCCESS = "ADD_LOCATIONS_SUCCESS";
export const ADD_LOCATIONS_FAIL = "ADD_LOCATIONS_FAIL";

export const DELETE_LOCATIONS = "DELETE_LOCATIONS";
export const DELETE_LOCATIONS_SUCCESS = "DELETE_LOCATIONS_SUCCESS";
export const DELETE_LOCATIONS_FAIL = "DELETE_LOCATIONS_FAIL";

export const EDIT_LOCATIONS = "EDIT_LOCATIONS";
export const EDIT_LOCATIONS_SUCCESS = "EDIT_LOCATIONS_SUCCESS";
export const EDIT_LOCATIONS_FAIL = "EDIT_LOCATIONS_FAIL";

export const UPDATE_LOCATIONS = "UPDATE_LOCATIONS";
export const UPDATE_LOCATIONS_SUCCESS = "UPDATE_LOCATIONS_SUCCESS";
export const UPDATE_LOCATIONS_FAIL = "UPDATE_LOCATIONS_FAIL";