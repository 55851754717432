import React, { useEffect, useState } from "react"
import { cilPencil, cilTrash } from "@coreui/icons"
import Image from 'react-bootstrap/Image'

import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import {
    Row,
    Col,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory, Link } from "react-router-dom"
import { toastConfirm } from "../../../common/toast"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import { startLoader } from "../../../store/loaderState/actions";
import { deleteLocations, editLocations, getLocations } from "../../../store/locations/actions"

const Locations = () => {
    document.title = "Admin | All Locations";

    const [searchKeyword, setSearchKeyword] = useState("")
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const dispatch = useDispatch();
    const history = useHistory();
    const { locations } = useSelector(state => state.Locations)
    const deleteLocationWithId = async (id) => {
        const response = await (await toastConfirm("Are you sure you want to delete this?")).fire();
        if (response.isConfirmed) {
              dispatch(deleteLocations( id , history))
        }
    }



    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
        dispatch(startLoader());
        dispatch(getLocations())
        console.log(locations);
    }, []);

    useEffect(() => {
        // dispatch(startLoader());
        dispatch(getLocations(page,searchKeyword))
    }, [page,searchKeyword]);


   const loadEditPage = (id) =>{
        dispatch(editLocations(
            id,history))
   }

    const columns = [
        {
            name: "Sr. no",
            selector: (row,index) => {
                
                return index+1
            },
            sortable: false,
        },
        {
            name: "Name",
            selector: row => row.name,
            sortable: true,
        },
        ,
        {
            name: "Address",
            selector: row => row.address,
            sortable: true,
        },
        {
            name: "Actions",
            selector: row => (
                <>
                    <Link to={`locations/${row._id}/edit`}>
                        <CIcon
                            icon={cilPencil}
                            className="text-warning hand me-2"
                            onClick={()=>{
                                // loadEditPage(row._id)
                            }}
                        />
                    </Link>
                    <CIcon
                        icon={cilTrash}
                        className="text-danger hand"
                        onClick={() => {
                            deleteLocationWithId(row._id)
                        }}
                    />
                </>
            ),
        },

    ]

    return (
        <React.Fragment>
            <div className="page-content cast ">
                <div className="container-fluid">
                    <Breadcrumbs title="All Locations" breadcrumbItem="Manage Locations" />

                    <Row>
                        <Col xl={12}>
                            <div className="mb-2">
                                <Link to={'/all-locations/add'} className="btn btn-primary w-md">Add +</Link>
                            </div>
                            <CCardGroup className="mb-2">
                                <CCard>
                                    <CCardBody>
                                        <div className="row">
                                            <div className="col-md-8">

                                            </div>
                                            <div className="col-md-4">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="search"
                                                    name="search"
                                                    placeHolder="Search Location"
                                                    onChange={e => {
                                                        setSearchKeyword(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                            <CCardGroup>
                                <CCard>
                                    <CCardBody>
                                        <DataTable
                                            striped
                                            columns={columns}
                                            data={locations?.list?.filter((item) => {
                                                if(searchKeyword === ""){
                                                    return item;
                                                }else if (
                                                    item.name.toLowerCase().includes(searchKeyword.toLowerCase())
                                                  ) {
                                                    return item;
                                                  }
                                                  else if (
                                                    item.address.toLowerCase().includes(searchKeyword.toLowerCase())
                                                  ) {
                                                    return item;
                                                  }
                                            })}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={locations?.list?.count}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={(page, totalRows) => {
                                                console.log('page : ', page);
                                                console.log('totalRows : ', totalRows);
                                                setPage(page)
                                            }}
                                        />
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                            

                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Locations
