
import React, { useEffect } from "react";

import { withRouter } from "react-router-dom";
import {
  changeSidebarType,
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import "../../components/CommonForBoth/rightbar.scss";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";

const Layout = props => {
  const dispatch = useDispatch();

  const { leftSideBarType,
    loadingState
  } = useSelector(state => ({
    leftSideBarType: state.Layout.leftSideBarType,
    loadingState : state.loadingState
  }));

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [loader,setLoader] = useState('block');

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };



  useEffect(() => {
    console.log('loadingState :' , loadingState)
    console.log('loader :' , loader)
    setLoader(loadingState.loadingState.display);
}, [loadingState]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
   

      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          // theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        
        <div className="main-content">
        <div id="preloader" style={{'display' : loader}}>
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
      {props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};



export default withRouter(Layout);
