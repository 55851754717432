import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import ClassesSaga from "./classes/saga"
import UsersSaga from "./users/saga"
import ProvidersSaga from "./providers/saga"
import LocationsSaga from "./locations/saga"
import CategoriesSaga from "./categories/saga"
import SplashScreenSaga from "./splashScreen/saga"
import CmsSaga from "./cms/saga"
import IncidentsSaga from "./incidents/saga"
import dashboardSaga from "./dashboard/saga"
import LoaderSaga from "./loaderState/saga"
import TransactionsSaga from "./transactions/saga"


export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(ClassesSaga),
    fork(UsersSaga),
    fork(LocationsSaga),
    fork(ProvidersSaga),
    fork(CategoriesSaga),
    fork(SplashScreenSaga),
    fork(CmsSaga),
    fork(LoaderSaga),
    fork(IncidentsSaga),
    fork(dashboardSaga),
    fork(TransactionsSaga),
  ])
}
