import React, { useEffect, useRef, useState } from "react"
import moment from "moment";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    Label,
} from "reactstrap"
import * as Yup from "yup"
import cloud from "../../../assets/flix-images/cloud-file-download.svg"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Field, Formik, Form } from "formik"
import { useHistory, useParams } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { getCountryCallingCode } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { fireToast } from "../../../common/toast";
import Dropzone from "react-dropzone";
import { editUsers, startLoader, updateUsers } from "../../../store/actions";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const UsersEdit = props => {

    //meta title
    document.title = "Edit User"
    const { singleUser } = useSelector((state) => state.Users)


    const uploadRef = useRef(null)
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const [image, setImage] = useState({
        blob: null,
        src: "",
    })
    const [data, setData] = useState({
        email: "",
        fname: "",
        lname: '',
        description: '',
        gender: '',
        phone: "",
        dob: "",
        file: "",
    })

    const EditUser = Yup.object().shape({
        email: Yup.string()
            .required("This field is required"),
        fname: Yup.string()
            .required("First Name is required"),
        lname: Yup.string()
            .required("Last Name is required"),
        gender: Yup.string()
            .required("This field is required"),
        phone: Yup.string()
            .required("This field is required"),
        dob: Yup.date()
            .required("This field is required"),
       

    })
    const handleImageChange = files => {
        const extn = ["image/jpg", "image/png", "image/jpeg"]
        const [file] = files

        if (file && extn.includes(file.type)) {

            if (file.size <= 5242880) {
                setData({ ...data, file })

                setImage({ blob: file, src: window.URL.createObjectURL(file) })
            } else {
                fireToast("error", "image too large")
            }
        } else {
            fireToast(
                "error",
                "Please select a valid image file(only jpg, png and jpeg images are allowed)"
            )
        }
    }
    const handleSubmit = (e) => {
        console.log(data);
        dispatch(startLoader());

        dispatch(updateUsers(
            params.id,
            data
            , history))
    }
    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))

        setTokenHeader(data.access_token)
        dispatch(startLoader());

        dispatch(editUsers(params.id));
        console.log(singleUser);
    }, [])

    useEffect(() => {
        if (singleUser) {
            setData({
                email: singleUser?.data?.users?.email,
                fname: singleUser?.data?.users?.fname,
                lname: singleUser?.data?.users?.lname,
                gender: singleUser?.data?.users?.gender,
                phone: singleUser?.data?.users?.phone,
                dob: singleUser?.data?.users?.dob,
                file: singleUser?.data?.users?.file,
                sessionRate: singleUser?.data?.users?.sessionRate
            })
        }
        console.log(singleUser.data)
    }, [singleUser])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="User" breadcrumbItem="Edit User" />
                    <Formik
                        initialValues={{ ...data }}
                        validationSchema={EditUser}
                        enableReinitialize
                        onSubmit={(e) => {
                            handleSubmit();
                            return false;
                        }}

                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Row>
                                <Col xl={8}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Email
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        value={data?.email}
                                                        onChange={e => {
                                                            setData({ ...data, email: e.target.value })
                                                            setFieldValue('email', e.target.value)
                                                        }}
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <div className="text-danger input-error">
                                                            {errors.email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        First Name
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="fname"
                                                        name="fname"
                                                        onChange={e => {
                                                            setData({ ...data, fname: e.target.value })
                                                            setFieldValue('fname', e.target.value)
                                                        }}
                                                    />
                                                    {errors.fname && touched.fname ? (
                                                        <div className="text-danger input-error">
                                                            {errors.fname}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Last Name
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="lname"
                                                        name="lname"
                                                        onChange={e => {
                                                            setData({ ...data, lname: e.target.value })
                                                            setFieldValue('lname', e.target.value)
                                                        }}
                                                    />
                                                    {errors.lname && touched.lname ? (
                                                        <div className="text-danger input-error">
                                                            {errors.lname}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="Type">Gender</Label>
                                                    <Field name="Type" className="form-control"
                                                        onChange={e => {
                                                            setData({ ...data, gender: e.target.value })
                                                        }}>
                                                        {({ field, form }) => (
                                                            <div className="sub-category-select">
                                                                <select id="gender" className="form-control input-color " name="gender" onChange={e => {
                                                                    setData({ ...data, gender: e.target.value })
                                                                }} value={data?.gender}>
                                                                    <option value="">Choose</option>
                                                                    <option value="1">Male</option>
                                                                    <option value="0">Female</option>
                                                                </select>
                                                                {form.touched.gender &&
                                                                    form.errors.gender ? (
                                                                    <div className="input-error text-danger">
                                                                        {form.errors.gender}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    </Field>

                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Phone
                                                    </Label>
                                                    <PhoneInput flags={flags}
                                                        defaultCountry="US"
                                                        onCountryChange={(v) => {
                                                            if(v != undefined){
                                                                setData({ ...data, countryCode: '+' + getCountryCallingCode(v) })
                                                                setFieldValue('countryCode', '+' + getCountryCallingCode(v))
                                                            }
                                                        }}
                                                        placeholder="Enter phone number"
                                                        value={data?.phone}
                                                        onChange={(setValue) => {
                                                            if (setValue != undefined) {
                                                                setData({ ...data, phone: setValue })
                                                                setFieldValue('phone', setValue)
                                                            }
                                                        }}
                                                    />
                                                    {errors.phone && touched.phone ? (
                                                        <div className="text-danger input-error">
                                                            {errors.phone}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        D O B
                                                    </Label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            type="date"
                                                            className="form-control"
                                                            id="dob"
                                                            name="dob"
                                                            value={dayjs(data?.dob)}
                                                            onChange={newValue => {
                                                                setData({ ...data, dob: newValue.$d })
                                                                setFieldValue('dob', newValue.$d)
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                    {errors.dob && touched.dob ? (
                                                        <div className="text-danger input-error">
                                                            {errors.dob}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Password
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="password"
                                                        name="password"
                                                        onChange={e => {
                                                            setData({ ...data, password: e.target.value })
                                                            setFieldValue('password', e.target.value)
                                                        }}
                                                    />
                                                    {errors.password && touched.password ? (
                                                        <div className="text-danger input-error">
                                                            {errors.password}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div >
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Image <small>(Optional)</small>
                                                    </Label>
                                                    <div className="text-center">

                                                        <div className="text-center">
                                                            <div className="mb-3 dragdrop-container">
                                                                <input
                                                                    ref={uploadRef}
                                                                    id="upload"
                                                                    hidden
                                                                    accept="image/*"
                                                                    type="file"
                                                                    onChange={e =>
                                                                        handleImageChange(e.target.files)
                                                                    }
                                                                />
                                                                {image.src ? (
                                                                    <div className="commonImgs">
                                                                        <img
                                                                            className="every-img"
                                                                            src={image.src ? image.src : cloud}
                                                                            alt=""
                                                                            onClick={() => {
                                                                                uploadRef.current.click()
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className="drag-n-drop-container">
                                                                        <div>
                                                                            <Dropzone
                                                                                accept="image/*"
                                                                                multiple={false}
                                                                                onDrop={acceptedFiles => {
                                                                                    handleImageChange(acceptedFiles)
                                                                                }}
                                                                            >
                                                                                {({
                                                                                    getRootProps,
                                                                                    getInputProps,
                                                                                    isDragActive,
                                                                                }) => (
                                                                                    <section>
                                                                                        <div
                                                                                            className="drop-area"
                                                                                            {...getRootProps()}
                                                                                        >
                                                                                            <img
                                                                                                width={60}
                                                                                                src={cloud}
                                                                                                alt=""
                                                                                            />
                                                                                            <input
                                                                                                {...getInputProps()}
                                                                                                accept="image/*"
                                                                                                multiple={false}
                                                                                            />

                                                                                            {isDragActive ? (
                                                                                                <div>
                                                                                                    Drop your image file here
                                                                                                </div>
                                                                                            ) : (
                                                                                                <p>
                                                                                                    Drag n drop image file here,
                                                                                                    or click to select <br />
                                                                                                    <small className="text-center">
                                                                                                        <strong>
                                                                                                            Supported files:
                                                                                                        </strong>{" "}
                                                                                                        jpeg, jpg, png. | Will be
                                                                                                        resized to: 1920x1080 px.
                                                                                                    </small>
                                                                                                </p>
                                                                                            )}
                                                                                        </div>
                                                                                    </section>
                                                                                )}
                                                                            </Dropzone>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <p className="fw-bold">
                                                            Note:
                                                            <span className="text-danger mx-2 text-align-left">
                                                                Supported image formats are:&nbsp;jpg, png and
                                                                jpeg only
                                                            </span>
                                                        </p>
                                                    </div>
                                                    {errors.image && touched.image ? (
                                                        <div className="text-danger input-error">
                                                            {errors.image}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {singleUser?.data?.users?.userProfile != null ? (
                                                    <div className="mb-2">
                                                    <label>Uploaded Image</label>
                                                   <div> <img
                                                        width={400}
                                                        src={`${process.env.REACT_APP_ASSET_URL}${singleUser?.data?.users?.userProfile}`}
                                                        alt=""
                                                    /></div>
                                                </div>
                                                ) : ('')}

                                                <div className="pt-5">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-md"
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    )
}


export default UsersEdit
