import React, { useEffect, useState } from "react"
import { cilPencil, cilTrash, cilClone, cilUser, cilFilter, cilVerticalAlignBottom } from "@coreui/icons"
import Image from 'react-bootstrap/Image'
import Archiver from "../../../assets/images/archive.png"
import unArchiver from "../../../assets/images/unArchive.png"
import { CCard, CCardBody, CCardGroup ,CDateRangePicker} from "@coreui/react"
import DataTable from "react-data-table-component"
import {
    Row,
    Col,
    Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory, Link } from "react-router-dom"
import { toastConfirm } from "../../../common/toast"
import { deleteCast, getCast } from "../../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import NoImgFound from "../../../assets/No-image-found.jpg"
import { startLoader } from "../../../store/loaderState/actions";
import { archiveClasses, deleteClasses, getClasses } from "../../../store/classes/actions"
import moment from "moment"
import axios from "axios"
import SelectAsyncPaginate from "./SelectAsyncPaginate"
import { Field } from "formik"
// import { DatePicker, LocalizationProvider} from "@mui/x-date-pickers"
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import 'bootstrap-daterangepicker/daterangepicker.css';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { LoadCategories, LoadInstructors, LoadLocations } from "../../../components/Common/dropDownApis"





const Classes = () => {
    document.title = "Admin | All Classes";


    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const history = useHistory();
    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        'categoryId': [],
        'locationId': [],
        'instructorId': [],
        'type': '',
        "showArchived" :false,
        'startDate': null,
        "endDate":null,
        "limit" :10
    })
   console.log(filterData.startDate)
   console.log(filterData.endDate)
    const [showFilter, setShowFilter] = useState(true)
    const loadTypes = [
        { 'id': 1, 'name': 'Public' },
        { 'id': 2, 'name': 'Private' },
    ]
    const { classes } = useSelector(state => state.Classes)
    const deleteClassWithId = async (id) => {
        const response = await (await toastConfirm("Are you sure you want to delete this?")).fire();
        if (response.isConfirmed) {
            dispatch(deleteClasses(id, history))
        }
    }

    const ArchiveClassWithId = async (id) => {
        const response = await (await toastConfirm("Are you sure you want to Archive this?")).fire();
        if (response.isConfirmed) {
            dispatch(archiveClasses(id, history))
        }
    }
    const UnArchiveClassWithId = async (id) => {
        const response = await (await toastConfirm("Are you sure you want to Un-Archive this?")).fire();
        if (response.isConfirmed) {
            dispatch(archiveClasses(id, history))
        }
    }

    

    const handleFilterState = (key, value) => {
        console.log(value)
        setFilterData((prev) => ({ ...prev, [key]: value }))
    }
    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const handleSelect = (event, picker) => {
        setFilterData({
          startDate: picker.startDate.toISOString(),
          endDate: picker.endDate.toISOString(),
        });
      };

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
        dispatch(startLoader());
        dispatch(getClasses(filterData))
    }, []);

    useEffect(() => {
        dispatch(getClasses(filterData))
    }, [filterData]);

    
    const loadTypesFn = async (searchQuery, loadedOptions, { page }) => {
        return {
            options: loadTypes,
            hasMore: false,
        };
    };
    const customSort = (rows, selector, direction) => {
        console.log(rows,selector)
        handleMultiChange({
            'sortBy': rows.actualKey,
            "order": selector == 'desc' ? -1 : 1
        })
    };



    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                if ((classes?.pagination?.page - 1) != 0) {

                    return ((classes?.pagination?.page - 1) * filterData.limit) + (index + 1)
                }
                return index + 1
            },
            sortable: true,
            width: "80px"
        },
        {
            name: "Category Name",
            selector: row => row.categoryId?.name,
            sortable: true,
            actualKey: 'categoryId.name',
            width: "400px" 
        },
        {
            name: "Class Name",
            selector: row => row.title,
            sortable: true,
            actualKey: 'title',
            width: "250px" 

        },
        {
            name: "Type",
            selector: row => {
                if(row.type == 1){
                    return 'public';
                }else if(row.type == 1){
                    return 'private';
                }else{
                    return 'N/A'
                }
            },
            sortable: false,
            actualKey: 'type',
            width: "250px" 

        },
        {
            name: "Location",
            selector: row => row.location?.name,
            sortable: true,
            actualKey: 'location.name',

            width: "250px" 
        },
        {
            name: "Instructor",
            selector: row => row.instructorId?.fname + ' ' + row.instructorId?.lname,
            sortable: true,
            actualKey:"instructorId.fname"
        },
        {
            name: "Date",
            selector: row => moment(row.date).format('MM/DD/YYYY'),
            sortable: true,
            actualKey: 'date'
        }, {
            name: "Time",
            selector: row => moment(row.startTime).format('hh:mm a').toLocaleString() + '-' + moment(row.endTime).format('hh:mm a').toLocaleString(),
            sortable: true,
            width: "170px" 
        },
        {
            name: "Actions",
            width: "200px",
            selector: row => (
                <>
                {row.isArchived ==false ? 
                   <>
                    <Link to={`classes/${row._id}/clone`} className="actionIcons">
                        <CIcon
                            icon={cilClone}
                            className="text-success hand me-2"
                            title="Clone This Class"
                            onClick={() => {
                                // toggleViewModal()

                                // setEdit(row);
                            }}
                        />
                    </Link>
                    <Link to={`classes/${row._id}/edit`} >
                        <CIcon
                            icon={cilPencil}
                            className="text-warning hand me-2"
                            title="Edit This Class"
                        />
                    </Link>
                    <img
                        src={Archiver}
                        className="text-danger hand"
                        title="Archive This Class"

                        onClick={() => {
                            ArchiveClassWithId(row._id)
                        }}
                    />
                    <Link to={`classes/${row._id}/users`} className="actionIcons">
                        <CIcon
                            icon={cilUser}
                            className="text-success hand "
                            style={{ 'height': '20px !important' }}
                            title="View Detail"
                        />
                    </Link>
                    <CIcon
                        icon={cilTrash}
                        className="text-danger hand"
                        title="Delete This Class"

                        onClick={() => {
                            deleteClassWithId(row._id)
                        }}
                    />
                    </>
                 : (<img
                    src={unArchiver}
                    className="text-danger hand"
                    title="Un-Archive This Class"

                    onClick={() => {
                        UnArchiveClassWithId(row._id)
                    }}
                />) }
                    
                </>
            ),
        },

    ]
    const ResetFilters = () => {


        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            'categoryId': [],
            'locationId': [],
            'instructorId': [],
            'type': '',
            "showArchived" : false,
            'date': '',
            'page' : 1,
            'limit' :10
        })
        // setFilterData({})

    }
    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setFilterData({
            limit: newRowsPerPage
          });
      };

    return (
        <React.Fragment>
            <div className="page-content cast ">
                <div className="container-fluid">
                    <Breadcrumbs title="All Classes" breadcrumbItem="Manage Class" />

                    <Row>
                        <Col xl={12}>
                            <div className="mb-2">
                                <Link to={'/all-classes/add'} className="btn btn-primary w-md">Add +</Link>
                            </div>
                            <CCardGroup className="mb-2">
                                <CCard>
                                    <CCardBody>
                                        <div className="row">
                                        <div className="col-md-6 mb-2 position-relative " >
                                                <label>Select Instructor:</label>
                                                <SelectAsyncPaginate
                                                    name="instructorId"
                                                    // regionName={region.value}
                                                    loadOptions={LoadInstructors}
                                                    label={"Instructor"}
                                                    isMulti={true}
                                                    value={filterData?.instructorId}
                                                    onChange={(instructorId) => { handleFilterState('instructorId', instructorId) }}
                                                />
                                            </div>
                                            <div className="col-md-6 ">
                                                <div className="w-100">
                                                    <label>Search :</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="search"
                                                        name="search"
                                                        placeholder="Search"
                                                        value={filterData?.search}
                                                        onChange={e => {
                                                            handleFilterState('search', e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className="w-25 d-none mt-4 d-flex justify-content-center align-items-center">
                                                <button className="border text-success p-2" onClick={() => {
                                                            setShowFilter(prevShowFilter => !prevShowFilter);
                                                        }}
                                                        style={{ 'cursor': 'pointer','border': '1px solid #556ee6','padding': '6px',
                                                        'borderRadius': '3px' }}
                                                        
                                                        >
                                                     <label className="mb-0">More Filters</label>
                                                   
                                                        <CIcon
                                                        icon={cilFilter}
                                                        className="text-primary hand searchFilter"
                                                        style={{ 'height': '15px !important', 'cursor': 'pointer' }}
                                                        title="View Detail"
                                                        
                                                    />
                                                    </button>

                                                </div>
                                            </div>

                                        </div>
                                        <div className={`row mt-2 ${showFilter == true ? '' : 'd-none'}`}>
                                            <div className="col-md-6 mb-2 position-relative " >
                                                <label>Select Location:</label>
                                                <SelectAsyncPaginate
                                                    name="location"
                                                    // regionName={region.value}
                                                    loadOptions={LoadLocations}
                                                    label={"Location"}
                                                    isMulti={true}
                                                    value={filterData?.locationId}
                                                    onChange={(location) => { handleFilterState('locationId', location) }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label>Select Date:</label>
                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        type="date"
                                                        className="form-control"
                                                        id="date"
                                                        name="date"
                                                        onChange={newValue => {
                                                             handleFilterState('date',newValue.$d.toISOString())
                                                           
                                                        }}
                                                    />
                                                </LocalizationProvider> */}
                                                <div>
                                                <DateRangePicker
                                                startDate={filterData.startDate}
                                                endDate={filterData.endDate}
                                                onApply={handleSelect}
                                                >
                                               <input type="text" className="form-control" 
                                               placeholder="Please select a date"
                                               />
                                               </DateRangePicker>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2 position-relative">
                                                <label>Select Category:</label>

                                                <SelectAsyncPaginate
                                                    name="category"
                                                    // regionName={region.value}
                                                    loadOptions={LoadCategories}
                                                    label="Category"
                                                    isMulti={true}
                                                    value={filterData.categoryId}
                                                    onChange={(category) => { handleFilterState('categoryId', category) }}
                                                />
                                            </div>


                                            <div className="col-md-6">
                                                <label>Class Type:</label>

                                                <SelectAsyncPaginate
                                                    name="type"
                                                    // regionName={region.value}
                                                    loadOptions={loadTypesFn}
                                                    label="Type"
                                                    isMulti={false}
                                                    value={filterData.type}
                                                    onChange={(type) => { handleFilterState('type', type) }}
                                                />
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                
                                                <div className="form-check form-switch my-2">
                                                <label className="form-check-label" for="ArchiveAwitch">Show Archived Classes</label>
                                                <input className="form-check-input" type="checkbox" role="switch"       id="ArchiveAwitch" onChange ={ (e) => {console.log(e.target.checked);handleFilterState('showArchived', e.target.checked)} }  checked={filterData.showArchived} />
                                                
                                                </div>
                                                <div>
                                                <input
                                                    type="button"
                                                    className="btn btn-primary"
                                                    id="search"
                                                    value="Reset"
                                                    onClick={() => ResetFilters()}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>

                            <CCardGroup>
                                <CCard>
                                    <CCardBody>
                                        <DataTable
                                            striped
                                            onSort={customSort}
                                            columns={columns}
                                            sortServer
                                            data={classes?.data}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={classes?.pagination?.total}
                                            paginationPerPage={filterData.limit}
                                            rowsPerPageOptions={[10,20,30]}
                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                            paginationComponentOptions={{ selectAllRowsItem: true,  }}
                                            onChangePage={(page, totalRows) => {;
                                                handleFilterState('page', page)
                                            }}
                                        />
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>


                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Classes
