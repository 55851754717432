import {
    GET_INCIDENTS_SUCCESS,
    GET_INCIDENTS_FAIL,
    ADD_INCIDENTS_SUCCESS,
    ADD_INCIDENTS_FAIL,
    DELETE_INCIDENTS_SUCCESS,
    DELETE_INCIDENTS_FAIL,
    EDIT_INCIDENTS_FAIL,
    EDIT_INCIDENTS_SUCCESS,
    UPDATE_INCIDENTS_SUCCESS,
    GET_REPORTEDINCIDENTS_SUCCESS,
    GET_REPORTEDINCIDENTS_FAIL
} from "./actionTypes";

const INIT_STATE = {
    incidents: {},
    error: {},
    singleIncidents: {},
    reportedIncidents: {}
};

const Incidents = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_INCIDENTS_SUCCESS:
            return {
                ...state,
                incidents: action.payload.data
            };
        case GET_INCIDENTS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case GET_REPORTEDINCIDENTS_SUCCESS:
            return {
                ...state,
                reportedIncidents: action.payload.data
            };
        case GET_REPORTEDINCIDENTS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case ADD_INCIDENTS_SUCCESS:
            return {
                ...state,
                incidents: {
                    ...state.incidents,
                    data: [...state.incidents.data, action.payload]
                },
            };
        case ADD_INCIDENTS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case EDIT_INCIDENTS_SUCCESS:
            console.log('EDIT incidents ====> ', action.payload.data.data)
            return {
                ...state,
                singleIncidents: action.payload.data.data
            };
        case EDIT_INCIDENTS_FAIL:
            return {
                ...state,
                error: action.payload
            };


        case UPDATE_INCIDENTS_SUCCESS:

            return {
                ...state,
                incidents: {
                    ...state.data,
                    data: state.incidents.data.map(item => item?.id == action.payload.id ? action.payload : item)
                },
            }

        case DELETE_INCIDENTS_SUCCESS:
            console.log('DELETE incidents ====> ', action.payload.data)
            return {
                ...state,
                incidents: {
                    ...state.incidents,
                    data: [...state.incidents.data.filter(item => item['id'] !== action.payload.data.id)],
                    pagination: { ...state.incidents.pagination, total: state.incidents.pagination.total - 1 }
                },
            };
        case DELETE_INCIDENTS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }

}


export default Incidents;