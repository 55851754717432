import React, { useEffect, useRef, useState } from "react"
import moment from "moment";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    Label,
} from "reactstrap"
import * as Yup from "yup"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Field, Formik, Form } from "formik"
import { useHistory, useParams } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { editClasses, updateClasses } from "../../../store/classes/actions";

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import axios from "axios";
import SelectAsyncPaginate from "./SelectAsyncPaginate"
import { fireToast } from "../../../common/toast";
import cloud from "../../../assets/flix-images/cloud-file-download.svg"
import Dropzone from "react-dropzone"
import { startLoader } from "../../../store/actions";
import { DatePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import { LoadCategories, LoadInstructors, LoadLocations } from "../../../components/Common/dropDownApis";
const ClassesEdit = props => {

    //meta title
    document.title = "Edit Class"
    const { singleClass } = useSelector((state) => state.Classes)



    const uploadRef = useRef(null)
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const [data, setData] = useState({
        title: "",
        startTime: '',
        endTime: '',
        date: '',
        description: "",
        price: "",
        type: "",
        category: {},
        duration: '',
        totalSeat: '',
        location: {},
        instructor: {},

    })

    const [image, setImage] = useState({
        blob: null,
        src: "",
    })
    const handleImageChange = files => {
        const extn = ["image/jpg", "image/png", "image/jpeg"]
        const [file] = files


        if (file && extn.includes(file.type)) {

            if (file.size <= 5242880) {
                setImage({ blob: file, src: window.URL.createObjectURL(file) })
                setData({ ...data, file })
            } else {
                fireToast("error", "image too large")
            }
        } else {
            fireToast(
                "error",
                "Please select a valid image file(only jpg, png and jpeg images are allowed)"
            )
        }
    }
    const EditClass = Yup.object().shape({
        title: Yup.string()
            .min(4, "Too short!!")
            .max(1000, "Too Long!!")
            .required("This field is required"),
        date: Yup.date()
            .required("This field is required"),
        startTime: Yup.date().required("This field is required"),
        endTime: Yup.date().required("This field is required"),
        description: Yup.string()
            .required("This field is required"),
        price: Yup.string()
            .required("This field is required"),
        duration: Yup.string()
            .required("This field is required"),
        location: Yup.object()
            .required("This field is required"),
        categoryId: Yup.object()
            .required("This field is required"),
        instructorId: Yup.object()
            .required("This field is required"),
        totalSeat: Yup.string()
            .required("This field is required"),
    })

    const handleSubmit = (e) => {
        const x = moment(data.startTime);
        const y = moment(data.endTime);
        console.log(x > y)
        console.log(x + ' > ' + y)
        if (x > y) {
            toast.error('End time Must be Greater Then Start Time.')
            return false;
        }
        if (Object.keys(data.categoryId).length == 0) {
            toast.error('Category feild is Required.')
            return false;
        }
        if (Object.keys(data.instructorId).length == 0) {
            toast.error('Instructor feild is Required.')
            return false;
        }
        if (Object.keys(data.location).length == 0) {
            toast.error('Instructor feild is Required.')
            return false;
        }
        dispatch(startLoader())
        console.log('data : ',data)
        dispatch(updateClasses(
            params.id,
            data
            , history))

    }
    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))

        setTokenHeader(data.access_token)

        dispatch(editClasses(params.id));
        console.log(singleClass);
    }, [])
    const processDate = (date) => {
        console.log(date)
        let Date = date.$d;
        Date = Date.toUTCString();
        return Date;

    }

    useEffect(() => {
        if (singleClass) {
            setData({
                title: singleClass.title,
                startTime: moment(singleClass.startTime).toLocaleString(),
                endTime: moment(singleClass.endTime).toLocaleString(),
                date: singleClass.date,
                description: singleClass.description,
                price: singleClass.price,
                type: singleClass.type,
                location: singleClass.location,
                lat: singleClass.lat,
                lng: singleClass.lng,
                ...singleClass
            });
        }
        console.log(data)
    }, [singleClass])

    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }
    

    useEffect(() => {
        if (data.startTime != '') {
            const startTime = moment(data.startTime);
            if (data.duration != 0) {
                var endTime = startTime.add(parseInt(data.duration), 'minutes');
                setData({ ...data, endTime: endTime })
            } else {
                setData({ ...data, endTime: '' })
            }
        } else {
            setData({ ...data, endTime: '' })
        }
    }, [data.duration, data.startTime])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Class" breadcrumbItem="Edit Class" />
                    <Formik
                        initialValues={{ ...data }}
                        validationSchema={EditClass}
                        enableReinitialize
                        onSubmit={(e) => {
                            handleSubmit();
                            return false;
                        }}

                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Row>
                                <Col xl={8}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Category
                                                    </Label>
                                                    <SelectAsyncPaginate
                                                        name="categoryId"
                                                        // regionName={region.value}
                                                        loadOptions={LoadCategories}
                                                        placeHolder="Select Location"
                                                        isMulti={false}
                                                        value={data.categoryId}
                                                        onChange={(categoryId) => {
                                                            setData({ ...data, categoryId }); console.log(categoryId);
                                                            console.log(data);
                                                        }}

                                                    />
                                                    {errors.categoryId && touched.categoryId ? (
                                                        <div className="text-danger input-error">
                                                            {errors.categoryId}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Instructor
                                                    </Label>
                                                    <SelectAsyncPaginate
                                                        name="instructorId"
                                                        // regionName={region.value}
                                                        loadOptions={LoadInstructors}
                                                        placeHolder="Select Instructor"
                                                        isMulti={false}
                                                        value={data.instructorId}
                                                        onChange={(instructorId) => {
                                                            setData({ ...data, instructorId }); console.log(instructorId);
                                                            console.log(data);
                                                        }}

                                                    />
                                                    {errors.instructorId && touched.instructorId ? (
                                                        <div className="text-danger input-error">
                                                            {errors.instructorId}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Title
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="title"
                                                        name="title"
                                                        onChange={e => {
                                                            setData({ ...data, title: e.target.value })
                                                            setFieldValue('title', e.target.value)
                                                            console.log(data);

                                                        }}
                                                    />
                                                    {errors.title && touched.title ? (
                                                        <div className="text-danger input-error">
                                                            {errors.title}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Date
                                                    </Label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            type="date"
                                                            className="form-control"
                                                            id="date"
                                                            name="date"
                                                            value={dayjs(data?.date)}
                                                            onChange={newValue => {
                                                                setData({ ...data, date: newValue.$d })
                                                                setFieldValue('date', newValue.$d)
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                    {errors.date && touched.date ? (
                                                        <div className="text-danger input-error">
                                                            {errors.date}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <div className="row">
                                                        <div className="col-md-6 d-flex flex-column">
                                                            <Label htmlFor="formrow-firstname-Input">
                                                                Start Time
                                                            </Label>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <TimePicker
                                                                    value={dayjs(data?.startTime)}
                                                                    ClassName="form-control"
                                                                    name="startTime"
                                                                    onChange={newValue => {
                                                                        var time = processDate(newValue)
                                                                        setData({ ...data, startTime: time })
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                            {errors.startTime && touched.startTime ? (
                                                                <div className="text-danger input-error">
                                                                    {errors.startTime}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-md-6 d-flex flex-column">
                                                            <Label htmlFor="formrow-firstname-Input">
                                                                End Time
                                                            </Label>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <TimePicker
                                                                    name="endTime"
                                                                    ClassName="form-control"
                                                                    value={dayjs(data?.endTime)}
                                                                    disabled={true}
                                                                    onChange={newValue => {
                                                                        var time = processDate(newValue)
                                                                        setData({ ...data, endTime: time })
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                            {errors.endTime && touched.endTime ? (
                                                                <div className="text-danger input-error">
                                                                    {errors.endTime}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Duration <small>( In minutes )</small>
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="duration"
                                                        name="duration"

                                                        onChange={e => {
                                                            setData({ ...data, duration: e.target.value })
                                                            setFieldValue('duration', e.target.value)

                                                        }}
                                                    />
                                                    {errors.duration && touched.duration ? (
                                                        <div className="text-danger input-error">
                                                            {errors.duration}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Description
                                                    </Label>
                                                    <Field
                                                        as="textarea"
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        rows="5"
                                                        onChange={e => {
                                                            setData({ ...data, description: e.target.value })
                                                            setFieldValue('description', e.target.value)
                                                            console.log(data);

                                                        }}
                                                    />
                                                    {errors.description && touched.description ? (
                                                        <div className="text-danger input-error">
                                                            {errors.description}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="Type">Type</Label>
                                                    <Field name="Type" className="form-control"
                                                        onChange={e => {
                                                            setData({ ...data, type: e.target.value })
                                                        }}>
                                                        {({ field, form }) => (
                                                            <div className="sub-category-select">
                                                                <select id="type" className="form-control input-color " name="type" onChange={e => {
                                                                    setData({ ...data, type: e.target.value })
                                                                    console.log(data);

                                                                }}
                                                                value={data?.type}>
                                                                    <option value="">Choose</option>
                                                                    <option value="1">Public</option>
                                                                    <option value="2">Private</option>
                                                                </select>
                                                                {form.touched.type &&
                                                                    form.errors.type ? (
                                                                    <div className="input-error text-danger">
                                                                        {form.errors.type}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    </Field>

                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        No. of Seats
                                                    </Label>
                                                    <Field
                                                        type="number"
                                                        className="form-control"
                                                        id="totalSeat"
                                                        name="totalSeat"
                                                        onChange={e => {
                                                            setData({ ...data, totalSeat: e.target.value })
                                                            setFieldValue('totalSeat', e.target.value)


                                                        }}
                                                    />
                                                    {errors.totalSeat && touched.totalSeat ? (
                                                        <div className="text-danger input-error">
                                                            {errors.totalSeat}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Price
                                                    </Label>
                                                    <Field
                                                        type="number"
                                                        className="form-control"
                                                        id="price"
                                                        name="price"
                                                        onChange={e => {
                                                            setData({ ...data, price: e.target.value })
                                                            setFieldValue('price', e.target.value)

                                                        }}
                                                    />
                                                    {errors.price && touched.price ? (
                                                        <div className="text-danger input-error">
                                                            {errors.price}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Location
                                                    </Label>
                                                    <SelectAsyncPaginate
                                                        name="location"
                                                        // regionName={region.value}
                                                        loadOptions={LoadLocations}
                                                        placeHolder="Select Location"
                                                        isMulti={false}
                                                        value={data.location}
                                                        onChange={(location) => {
                                                            console.log(location)
                                                            setData({ ...data, location }); console.log(location);
                                                            console.log(data);
                                                        }}

                                                    />
                                                    {errors.location && touched.location ? (
                                                        <div className="text-danger input-error">
                                                            {errors.location}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div >
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Image <small>(required)</small>
                                                    </Label>
                                                    <div className="text-center">

                                                        <div className="text-center">
                                                            <div className="mb-3 dragdrop-container">
                                                                <input
                                                                    ref={uploadRef}
                                                                    id="upload"
                                                                    hidden
                                                                    accept="image/*"
                                                                    type="file"
                                                                    onChange={e =>
                                                                        handleImageChange(e.target.files)
                                                                    }
                                                                />
                                                                {image.src ? (
                                                                    <div className="commonImgs">
                                                                        <img
                                                                            className="every-img"
                                                                            src={image.src ? image.src : cloud}
                                                                            alt=""
                                                                            onClick={() => {
                                                                                uploadRef.current.click()
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className="drag-n-drop-container">
                                                                        <div>
                                                                            <Dropzone
                                                                                accept="image/*"
                                                                                multiple={false}
                                                                                onDrop={acceptedFiles => {
                                                                                    handleImageChange(acceptedFiles)
                                                                                }}
                                                                            >
                                                                                {({
                                                                                    getRootProps,
                                                                                    getInputProps,
                                                                                    isDragActive,
                                                                                }) => (
                                                                                    <section>
                                                                                        <div
                                                                                            className="drop-area"
                                                                                            {...getRootProps()}
                                                                                        >
                                                                                            <img
                                                                                                width={60}
                                                                                                src={cloud}
                                                                                                alt=""
                                                                                            />
                                                                                            <input
                                                                                                {...getInputProps()}
                                                                                                accept="image/*"
                                                                                                multiple={false}
                                                                                            />

                                                                                            {isDragActive ? (
                                                                                                <div>
                                                                                                    Drop your image file here
                                                                                                </div>
                                                                                            ) : (
                                                                                                <p>
                                                                                                    Drag n drop image file here,
                                                                                                    or click to select <br />
                                                                                                    <small className="text-center">
                                                                                                        <strong>
                                                                                                            Supported files:
                                                                                                        </strong>{" "}
                                                                                                        jpeg, jpg, png. | Will be
                                                                                                        resized to: 1920x1080 px.
                                                                                                    </small>
                                                                                                </p>
                                                                                            )}
                                                                                        </div>
                                                                                    </section>
                                                                                )}
                                                                            </Dropzone>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <p className="fw-bold">
                                                            Note:
                                                            <span className="text-danger mx-2 text-align-left">
                                                                Supported image formats are:&nbsp;jpg, png and
                                                                jpeg only
                                                            </span>
                                                        </p>
                                                        {errors.file && touched.file ? (
                                                            <div className="text-danger input-error">
                                                                {errors.file}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                {singleClass.classImage ? (<div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Uploaded
                                                    </Label>
                                                    <div>
                                                        <img
                                                            className="previousImage"
                                                            src={`${process.env.REACT_APP_ASSET_URL}${singleClass.classImage}`}
                                                            alt=""
                                                            style={{ maxHeight: '120px' }}
                                                        /></div>
                                                </div>) : ('')}
                                                <div className="pt-5">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-md"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    )
}


export default ClassesEdit
