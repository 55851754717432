/** Get Dashboard Chart data */
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

export const ADD_CATEGORIES = "ADD_CATEGORIES";
export const ADD_CATEGORIES_SUCCESS = "ADD_CATEGORIES_SUCCESS";
export const ADD_CATEGORIES_FAIL = "ADD_CATEGORIES_FAIL";

export const DELETE_CATEGORIES = "DELETE_CATEGORIES";
export const DELETE_CATEGORIES_SUCCESS = "DELETE_CATEGORIES_SUCCESS";
export const DELETE_CATEGORIES_FAIL = "DELETE_CATEGORIES_FAIL";

export const EDIT_CATEGORIES = "EDIT_CATEGORIES";
export const EDIT_CATEGORIES_SUCCESS = "EDIT_CATEGORIES_SUCCESS";
export const EDIT_CATEGORIES_FAIL = "EDIT_CATEGORIES_FAIL";

export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES"
export const UPDATE_CATEGORIES_SUCCESS = "UPDATE_CATEGORIES_SUCCESS"
export const UPDATE_CATEGORIES_FAIL = "UPDATE_CATEGORIES_FAIL"