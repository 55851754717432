import axios from "axios";

export const LoadCategories = async (searchQuery, loadedOptions, { page }) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token}`;
        const responseJSON = await axios.get(`${process.env.REACT_APP_ALL_OUT_FTINESS_URL}/getCategory?page=${page}`);
        console.log(responseJSON.data)
        return {
            options: responseJSON.data.data,
            // hasMore: responseJSON.length >= 1,
            hasMore: page < responseJSON.data.pagination.totalPages ? true : false,
            additional: {
                page: searchQuery ? 2 : page + 1,
            },
        };
    } catch (error) {
        console.log("err : ", error);
        return {
            options: [],
            hasMore: false,
            additional: { page: 1 },
        };
    }
};
export const LoadLocations = async (searchQuery, loadedOptions, { page }) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token}`;
        const responseJSON = await axios.get(`${process.env.REACT_APP_ALL_OUT_FTINESS_URL}/location?page=${page}`);
        console.log(responseJSON.data)
        return {
            options: responseJSON.data.list,
            // hasMore: responseJSON.length >= 1,
            hasMore: page < responseJSON.data.pagination.totalPages ? true : false,
            additional: {
                page: searchQuery ? 2 : page + 1,
            },
        };
    } catch (error) {
        console.log("err : ", error);
        return {
            options: [],
            hasMore: false,
            additional: { page: 1 },
        };
    }
};
export const LoadInstructors = async (searchQuery, loadedOptions, { page }) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token}`;
        const responseJSON = await axios.get(`${process.env.REACT_APP_ALL_OUT_FTINESS_URL}/provider?page=${page}`);
        console.log(responseJSON.data)
        return {
            options: responseJSON.data.list,
            // hasMore: responseJSON.length >= 1,
            hasMore: page < responseJSON.data.pagination.totalPages ? true : false,
            additional: {
                page: searchQuery ? 2 : page + 1,
            },
        };
    } catch (error) {
        console.log("err : ", error);
        return {
            options: [],
            hasMore: false,
            additional: { page: 1 },
        };
    }
};

export const LoadUsers = async (searchQuery, loadedOptions, { page }) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token}`;
        const responseJSON = await axios.get(`${process.env.REACT_APP_ALL_OUT_FTINESS_URL}/user?page=${page}`);
        console.log('responseJSON.data : ' ,responseJSON.data)
        const list = responseJSON.data.users
        return {
            options: list,
            // hasMore: responseJSON.length >= 1,
            hasMore: page < responseJSON.data.pagination.totalPages ? true : false,
            additional: {
                page: searchQuery ? 2 : page + 1,
            },
        };
    } catch (error) {
        console.log("err : ", error);
        return {
            options: [],
            hasMore: false,
            additional: { page: 1 },
        };
    }
};