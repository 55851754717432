import {
    GET_LOCATIONS_SUCCESS,
    GET_LOCATIONS_FAIL,
    ADD_LOCATIONS_SUCCESS,
    ADD_LOCATIONS_FAIL,
    DELETE_LOCATIONS_SUCCESS,
    DELETE_LOCATIONS_FAIL,
    EDIT_LOCATIONS_FAIL,
    EDIT_LOCATIONS_SUCCESS,
    UPDATE_LOCATIONS_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    locations: {},
    error: {},
    singleLocation: {}
};

const Locations = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_LOCATIONS_SUCCESS:
            console.log('locations ====> ', action.payload.data)
            return {
                ...state,
                locations: action.payload.data
            };
        case GET_LOCATIONS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case ADD_LOCATIONS_SUCCESS:
            console.log('Add locations ====> ', action.payload.data)
            return {
                ...state,
                locations: {
                    ...state.data,
                    data: [...state.data, action.payload]
                },
            };
        case ADD_LOCATIONS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case EDIT_LOCATIONS_SUCCESS:
            console.log('EDIT_LOCATIONS_SUCCESS : ' , action.payload.data.data)
            return {
                ...state,
                singleLocation: action.payload.data?.data
            };
        case EDIT_LOCATIONS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_LOCATIONS_SUCCESS:
            console.log('Delete locations ====> ', action.payload.data)
            return {
                ...state,
                locations: {
                    ...state.locations,
                    list: [...state.locations.list.filter(item => item['_id'] !== action.payload.data.id)],
                    pagination:{...state.locations.pagination ,total:state.locations.pagination.total -1}
                },
            };
        case DELETE_LOCATIONS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case UPDATE_LOCATIONS_SUCCESS:

            return {
                ...state,
                locations: {
                    ...state.locations,
                    data: state.locations.data.map(item => item?.id == action.payload.id ? action.payload : item)
                },
            }

        default:
            return state;
    }

}


export default Locations;