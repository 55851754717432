import React, { useEffect, useRef, useState } from "react"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    Label,
} from "reactstrap"
import * as Yup from "yup"
import MyGoogleMap from "../GoogleMap/MyGoogleMap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Formik, Form, Field } from "formik"
import { fireToast } from "../../../common/toast"
import { Link, useHistory } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { useDispatch } from "react-redux";
import { addLocations } from "../../../store/locations/actions";
import axios from "axios"
import cloud from "../../../assets/flix-images/cloud-file-download.svg"
import Dropzone from "react-dropzone"
import { stopLoader } from "../../../store/actions"

const LocationsAdd = props => {
    const uploadRef = useRef(null)
    //meta title
    document.title = "Add Location"



    const history = useHistory();
    const dispatch = useDispatch();

    const [data, setData] = useState({

        location: "",
        lat: "",
        lng: "",
        name: '',
        description:"",
        files: [],
        filteredFiles: ""
    })
    const [selectedFiles, setSelectedFiles] = useState([])

    const addLocation = Yup.object().shape({
        lat: Yup.string().required(),
        lng: Yup.string().required(),
        location: Yup.string().required(),
        name: Yup.string().required(),
        description: Yup.string().required(),
        filteredFiles: Yup.array().required()
    })
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const handleSubmit = async (e) => {
        const uploadedFiles = await UploadFiles(data?.filteredFiles);
        await delay(1000);
        dispatch(addLocations(
            { data, uploadedFiles }
            , history))
    }

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))

        setTokenHeader(data.access_token)
        dispatch(stopLoader());

    }, [])

    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    const UploadFiles = async (Files = []) => {
        let UploadedFiles = [];
        try {
            await Files.map(function (file, index) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token}`;
                const responseJSON = axios.post(`${process.env.REACT_APP_ALL_OUT_FTINESS_URL}/test`, { file }, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(function (response) {
                        UploadedFiles.push(response.data.data);
                        console.log('response : ', response.data.data)
                    })
                    .catch(function (error) {
                        console.log('error : ', error.response.message)
                    });
            })
        } catch (error) {
            console.log("err : ", error);
        }
        return UploadedFiles;
    };
    const handleAcceptedFiles = async (filesData) => {
        const extn = ["image/jpg", "image/png", "image/jpeg"]
        // console.log(files);
        const filteredFiles = [];
        const files = filesData.map(file => {
            if (file && extn.includes(file.type)) {

                if (file.size <= 5242880) {
                    console.log(file);

                    filteredFiles.push(file)
                    return Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        formattedSize: formatBytes(file.size),
                    })
                } else {
                    fireToast("error", "image too large")
                }
            } else {
                fireToast(
                    "error",
                    "Please select a valid image file(only jpg, png and jpeg images are allowed)"
                )
            }
        }

        )

        setSelectedFiles(filteredFiles)
        setData({ ...data, filteredFiles })

    }

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    const getLatLng = async (lat, lng, placeObj = '') => {
        if (placeObj !== '') {
            setData({ ...data, location: placeObj.formatted_address, lat: lat, lng: lng })
        } else {
            if(lat != '' && lng != ''){
                try {
                    let response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDvCd54TfWJRxnwGDbC-iLcwwADlGQrFmA&sensor=true`);
                    console.log()
                    setData({ ...data, location: response.data.results[0].formatted_address, lat: lat, lng: lng })
                } catch (error) {
                        console.log(error)
                }
           
            }
        }
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Location" breadcrumbItem="Add Location" />
                    <Formik
                        initialValues={{ ...data }}
                        validationSchema={addLocation}
                        enableReinitialize
                        onSubmit={(e) => {
                            handleSubmit();
                            return false;
                        }}

                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Row>

                                <Col xl={8}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Name
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        onChange={e => {
                                                            setData({ ...data, name: e.target.value })
                                                            setFieldValue('name', e.target.value)
                                                        }}
                                                    />
                                                    {errors.name && touched.name ? (
                                                        <div className="text-danger input-error">
                                                            {errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Description
                                                    </Label>
                                                    <Field
                                                        as="textarea"
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        rows="5"
                                                        onChange={e => {
                                                            setData({ ...data, description: e.target.value })
                                                            setFieldValue('description', e.target.value)
                                                        }}
                                                    />
                                                    {errors.description && touched.description ? (
                                                        <div className="text-danger input-error">
                                                            {errors.description}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="row">

                                                

                                                <div style={{ 'height': '400px', 'position': 'relative' }} className="mb-3 col-md-12">
                                                    <Label htmlFor="formrow-firstname-Input " className="d-block mb-0 pb-0">
                                                        Location 
                                                    </Label>
                                                    <span className="text-danger text-center "><small>Location services for this url must be authorized on current browser for map to function properly</small></span>
                                                    <MyGoogleMap getLatLng={getLatLng} />

                                                </div>
                                                </div>
                                                <hr></hr>
                                                {errors.location && touched.location ? (
                                                    <div className="text-danger input-error">
                                                        {errors.location}
                                                    </div>
                                                ) : null}
                                                <div className="mb-3 col-md-12 pt-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Images <small>(minimum 1 Image required)</small>
                                                    </Label>
                                                    <div className="text-center">

                                                        <div className="text-center">
                                                            <div className="mb-3 dragdrop-container">
                                                                <input
                                                                    ref={uploadRef}
                                                                    id="upload"
                                                                    hidden
                                                                    accept="image/*"
                                                                    type="file"
                                                                    multiple={true}
                                                                    onChange={e => {
                                                                        handleAcceptedFiles(e.target.files)
                                                                        console.log(e.target.files)
                                                                    }
                                                                    }
                                                                />

                                                                <div className="drag-n-drop-container">
                                                                    <div>
                                                                        <Dropzone
                                                                            accept="image/*"
                                                                            multiple={true}
                                                                            // onDrop={acceptedFiles => {
                                                                            //     handleImageChange(acceptedFiles)
                                                                            // }}
                                                                            onDrop={acceptedFiles =>
                                                                                handleAcceptedFiles(acceptedFiles)
                                                                            }
                                                                        >
                                                                            {({
                                                                                getRootProps,
                                                                                getInputProps,
                                                                                isDragActive,
                                                                            }) => (
                                                                                <section>
                                                                                    <div
                                                                                        className="drop-area"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <img
                                                                                            width={60}
                                                                                            src={cloud}
                                                                                            alt=""
                                                                                        />
                                                                                        <input
                                                                                            {...getInputProps()}
                                                                                            accept="images/*"
                                                                                            multiple={true}
                                                                                        />

                                                                                        {isDragActive ? (
                                                                                            <div>
                                                                                                Drop your image file here
                                                                                            </div>
                                                                                        ) : (
                                                                                            <p>
                                                                                                Drag n drop image file here,
                                                                                                or click to select <br />
                                                                                                <small className="text-center">
                                                                                                    <strong>
                                                                                                        Supported files:
                                                                                                    </strong>{" "}
                                                                                                    jpeg, jpg, png. | Will be
                                                                                                    resized to: 1920x1080 px.
                                                                                                </small>
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </section>
                                                                            )}
                                                                        </Dropzone>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {errors.filteredFiles && touched.filteredFiles ? (
                                                            <div className="text-danger input-error">
                                                                Image is required
                                                            </div>
                                                        ) : null}
                                                        <p className="fw-bold">
                                                            Note:
                                                            <span className="text-danger mx-2 text-align-left">
                                                                Supported image formats are:&nbsp;jpg, png and
                                                                jpeg only
                                                            </span>
                                                        </p>
                                                        <div
                                                            className="dropzone-previews mt-3"
                                                            id="file-previews"
                                                        >
                                                            {selectedFiles.map((f, i) => {
                                                                return (
                                                                    <Card
                                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                        key={i + "-file"}
                                                                    >
                                                                        <div className="p-2">
                                                                            <Row className="align-items-center">
                                                                                <Col className="col-auto">
                                                                                    <img
                                                                                        data-dz-thumbnail=""
                                                                                        height="80"
                                                                                        className="avatar-sm rounded bg-light"
                                                                                        alt={f.name}
                                                                                        src={f.preview}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <Link
                                                                                        to="#"
                                                                                        className="text-muted font-weight-bold"
                                                                                    >
                                                                                        {f.name}
                                                                                    </Link>
                                                                                    <p className="mb-0">
                                                                                        <strong>{f.formattedSize}</strong>
                                                                                    </p>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Card>
                                                                )
                                                            })}
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="pt-5">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-md"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    )
}


export default LocationsAdd
