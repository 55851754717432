import {
    GET_SPLASH_SCREEN_SUCCESS,
    GET_SPLASH_SCREEN_FAIL,
    ADD_SPLASH_SCREEN_SUCCESS,
    ADD_SPLASH_SCREEN_FAIL,
    DELETE_SPLASH_SCREEN_SUCCESS,
    DELETE_SPLASH_SCREEN_FAIL,
    EDIT_SPLASH_SCREEN_FAIL,
    EDIT_SPLASH_SCREEN_SUCCESS,
    UPDATE_SPLASH_SCREEN_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    splashScreen: {},
    error: {},
    singleSplashScreen: {}
};

const SplashScreen = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_SPLASH_SCREEN_SUCCESS:
            console.log('splashScreen ====> ', action.payload.data)
            return {
                ...state,
                splashScreen: action.payload.data
            };
        case GET_SPLASH_SCREEN_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case ADD_SPLASH_SCREEN_SUCCESS:
            console.log('Add splashScreen ====> ', action.payload.data)
            return {
                ...state,
                splashScreen: {
                    ...state.getScreen,
                    getScreen: [...state.splashScreen.getScreen, action.payload.data.data]
                },
            };
        case ADD_SPLASH_SCREEN_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case EDIT_SPLASH_SCREEN_SUCCESS:
            return {
                ...state,
                singleSplashScreen: action.payload.data.data
            };
        case EDIT_SPLASH_SCREEN_FAIL:
            return {
                ...state,
                error: action.payload
            };
        

      case UPDATE_SPLASH_SCREEN_SUCCESS:
        console.log('update splashScreen ====> ', action.payload)

          return {
            ...state,
            splashScreen: {
              ...state.getScreen,
              getScreen: state.splashScreen.getScreen.map(item => item?.id == action.payload.id ? action.payload : item)
            },
          }

        case DELETE_SPLASH_SCREEN_SUCCESS:
            console.log('DELETE splashScreen ====> ', action.payload.data.id)
            return {
                ...state,
                splashScreen: {
                    ...state.getScreen,
                    getScreen: [...state.splashScreen.getScreen.filter(item => item['_id'] !== action.payload.data.id)]
                },
            };
        case DELETE_SPLASH_SCREEN_FAIL:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }

}


export default SplashScreen;