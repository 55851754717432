import React, { useEffect, useState } from "react"
import { cilPencil, cilTrash } from "@coreui/icons"
import Image from 'react-bootstrap/Image'

import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import {
    Row,
    Col,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory, Link } from "react-router-dom"
import { toastConfirm } from "../../../common/toast"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import NoImgFound from "../../../assets/No-image-found.jpg"
import { startLoader } from "../../../store/loaderState/actions";
import {  getReportedIncidents } from "../../../store/incidents/actions"
import moment from "moment"

const ReportedIncidents = () => {
    document.title = "Admin | All Reported Incidents";
    const { loadingState } = useSelector(state => state.loadingState)
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const dispatch = useDispatch();
    const history = useHistory();
    const { reportedIncidents } = useSelector(state => state.Incidents)
    



    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
        dispatch(startLoader());
        dispatch(getReportedIncidents())
        console.log(ReportedIncidents);
    }, []);

    useEffect(() => {
        dispatch(startLoader());
        dispatch(getReportedIncidents(page))
    }, [page]);

    useEffect(() => {
        console.log(reportedIncidents);
    }, [reportedIncidents]);

    const columns = [
        {
            name: "Sr. no",
            selector: (row ,index)=> {
                if((reportedIncidents?.pagination?.page - 1) !=0){
                   
                    return ((reportedIncidents?.pagination?.page-1)*10) + (index+1)
               }
               return index+1
            },
            sortable: true,
        },

        {
            name: "Class",
            selector: row => row.classId?.title ?? 'Not-Avialable',
            sortable: true,
        },
        {
            name: "Class Date",
            selector: row => row.classId?.date ?? 'Not-Avialable',
            sortable: true,
        },
        {
            name: "Location",
            selector: row => row.classId?.location?.name ?? 'Not-Avialable',
            sortable: true,
        },

        {
            name: "Instructor ",
            selector: row => row.instructorId.fname+' '+ row.instructorId.lname,
            sortable: true,
        },

        {
            name: "Incident",
            selector: row => row.incidentId?.name,
            sortable: true,
        },

        {
            name: "Reported Date",
            selector: row => moment(row.createdAt).format('MM/DD/YYYY'),
            sortable: true,
        },
        {
            name: "Reported Time",
            selector: row => moment(row.createdAt).format('hh:mm a'),
            sortable: true,
        },
        {
            name: "Comments",
            selector: row => row.description,
            sortable: true,
        }

    ]

    return (
        <React.Fragment>
            <div className="page-content cast ">
                <div className="container-fluid">
                    <Breadcrumbs title="All Reported Incidents" breadcrumbItem="Manage Reported Incidents" />

                    <Row>
                        <Col xl={12}>
                            <CCardGroup>
                                <CCard>
                                    <CCardBody>
                                        <DataTable
                                            striped
                                            columns={columns}
                                            data={reportedIncidents?.data}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={reportedIncidents?.pagination?.total}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={(page, totalRows) => {
                                                console.log('page : ', page);
                                                console.log('totalRows : ', totalRows);
                                                setPage(page)
                                            }}
                                        />
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                            

                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ReportedIncidents
