import axios from "axios";

//pass new generated access token here
// const token = accessToken;

//apply base url for axios
const API_URL = `${process.env.REACT_APP_ALL_OUT_FTINESS_URL}`;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = '';

export function setTokenHeader(token) {
  axiosApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  // axiosApi.defaults.headers.common['Content-Type'] = 'multipart/form-data';
}

// axiosApi.interceptors.response.use(
//   response => {
//     console.log(response.config.url, "Response :", response.data); 
//     return response
//   },
//   error => {
//     console.log("API Error :", error);
//     // Promise.reject(error)
//     return error
//   }
// );

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response)
    .catch(function (error){
      console.log('error : ' , error.response.data.message)
        return error.response.data
    });
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response);
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(function (error){
      console.log('error : ' , error)
        return error.response.data.message
    });
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
