// MyGoogleMaps.js
import React, { Component } from "react";

import GoogleMapReact from "google-map-react";

import styled from "styled-components";

import AutoComplete from "./Autocomplete";
import Marker from "./Marker";

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

class MyGoogleMap extends Component {
  state = {
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: [],
    zoom: 9,
    address: this.props.singleLocation?.address,
    address_line: "",
    state: "",
    country: "",
    city: "",
    zip_code: "",
    draggable: true,
    id:this.props.singleLocation?.id,
    name:this.props.singleLocation?.name,
    lat: (this.props.singleLocation?.length > 1) ? this.props.singleLocation?.coordinates[0] : '',
    lng: (this.props.singleLocation?.length > 1) ? this.props.singleLocation?.coordinates[1] : '',
    single:this.props.singleLocation
  };

  componentWillMount() {
     console.log('heeelo ')
     console.log(this.state)
    this.setCurrentLocation();
  }

  onMarkerInteraction = (childKey, childProps, mouse) => {
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };
  onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true });
    this._generateAddress();
  };

  _onChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom,
    });
  };

  _onClick = (value) => {
    console.log( value)
    this.setState({
      lat: value.lat,
      lng: value.lng,
    });
    this.props.getLatLng(value.lat,value.lng,'')

  };

  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });

    this._generateAddress();
  };

  addPlace = (place) => {
    console.log('place=====>',place)
    this.props.getLatLng(place.geometry.location.lat(),place.geometry.location.lng() ,place)
    this.setState({
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    this._generateAddress();
  };

  _generateAddress() {
    const { mapApi } = this.state;

  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    console.log(this.props?.singleLocation)
    if (this.props?.singleLocation?.coordinates[0] && this.props?.singleLocation?.coordinates[1]) {
      // this.setState(this.props?.location);
      navigator.geolocation.getCurrentPosition((position) => {
        this.props.getLatLng(this.props?.singleLocation?.coordinates[0],this.props?.singleLocation?.coordinates[1],'')
        this.setState({
          center: [this.props?.singleLocation?.coordinates[0], this.props?.singleLocation?.coordinates[1]],
          lat: this.props?.singleLocation?.coordinates[0],
          lng: this.props?.singleLocation?.coordinates[1],
        });
      });
    } else if ("geolocation" in navigator) {
      console.log(navigator.geolocation)
      navigator.geolocation.getCurrentPosition((position) => {
        this.props.getLatLng(position.coords.latitude,position.coords.longitude)
        this.setState({
          center: [position.coords.latitude, position.coords.longitude],
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }

  render() {
    const { places, mapApiLoaded, mapInstance, mapApi } = this.state;

    return (
      <Wrapper>
        {mapApiLoaded && (
          <div>
            <AutoComplete
              map={mapInstance}
              mapApi={mapApi}
              addplace={this.addPlace}
              address={this.state.address}
            />
          </div>
        )}
        <GoogleMapReact
          center={this.state.center}
          zoom={this.state.zoom}
          draggable={this.state.draggable}
          onChange={this._onChange}
          onChildMouseDown={this.onMarkerInteraction}
          onChildMouseUp={this.onMarkerInteractionMouseUp}
          onChildMouseMove={this.onMarkerInteraction}
          onChildClick={() => console.log("child click")}
          onClick={this._onClick}
          bootstrapURLKeys={{
            key: "AIzaSyDvCd54TfWJRxnwGDbC-iLcwwADlGQrFmA",
            // libraries: ["places", "geometry"],
            libraries: ["places"],
          }}
          options={{
            fullscreenControl: false,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
        >
          <Marker
            text={this.state.address}
            lat={this.state.lat}
            lng={this.state.lng}
          />
        </GoogleMapReact>

   
      </Wrapper>
    );
  }
}

export default MyGoogleMap;
