import {
    GET_TRANSACTIONS_SUCCESS,
    GET_TRANSACTIONS_FAIL,
    ADD_TRANSACTIONS_SUCCESS,
    ADD_TRANSACTIONS_FAIL,
    DELETE_TRANSACTIONS_SUCCESS,
    DELETE_TRANSACTIONS_FAIL,
    EDIT_TRANSACTIONS_FAIL,
    EDIT_TRANSACTIONS_SUCCESS,
    UPDATE_TRANSACTIONS_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    transactions: {},
    error: {},
    singleTransaction: {}
};

const Transactions = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_TRANSACTIONS_SUCCESS:
            console.log('transaction Data : ' , action.payload.data)
            return {
                ...state,
                transactions: action.payload.data
            };
        case GET_TRANSACTIONS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case ADD_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    data: [...state.transactions.data, action.payload]
                },
            };
        case ADD_TRANSACTIONS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case EDIT_TRANSACTIONS_SUCCESS:
            console.log('EDIT transactions ====> ', action.payload.data.data)
            return {
                ...state,
                singleTransaction: action.payload.data.data
            };
        case EDIT_TRANSACTIONS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        

      case UPDATE_TRANSACTIONS_SUCCESS:

          return {
            ...state,
            transactions: {
              ...state.transactionsData,
              transactionsData: state.transactions.data.map(item => item?.id == action.payload.id ? action.payload : item)
            },
          }

        case DELETE_TRANSACTIONS_SUCCESS:
            console.log('DELETE transactions ====> ', action.payload.data)
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    data: [...state.transactions.data.filter(item => item['id'] !== action.payload.data.id)],
                    pagination:{...state.transactions.pagination ,total:state.transactions.pagination.total -1}
                },
            };
        case DELETE_TRANSACTIONS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }

}


export default Transactions;