import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withRouter, useHistory } from "react-router-dom"


//redux

const Logout = props => {
  let history = useHistory();

  useEffect(() => {
    sessionStorage.clear()
    history.push('/login')
  }, [])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
