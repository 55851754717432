import { withRouter } from "react-router-dom"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../helpers/api_helper"
import { startLoader } from "../../store/loaderState/actions";
import { getChartsData } from "../../store/dashboard/actions"
import moment from "moment"

const Dashboard = () => {
    document.title = "Admin | Dashboard";


    const dispatch = useDispatch();
    const { chartsData } = useSelector(state => state.Dashboard)
    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
        dispatch(startLoader());
        dispatch(getChartsData())
        console.log(chartsData);
    }, []);
    return (
        <React.Fragment>
            <div className="page-content cast ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="mini-stats-wid card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Total Users</p>
                                            <h4 className="mb-0">{chartsData?.data?.totalUser}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className="bx bx-user-circle font-size-24">
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mini-stats-wid card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Total Providers</p>
                                            <h4 className="mb-0">{chartsData?.data?.totalInstructor}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className="bx bx-user-circle font-size-24">
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mini-stats-wid card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Total Classes</p>
                                            <h4 className="mb-0">{chartsData?.data?.totalClass}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className="bx bx-calendar-event font-size-24">
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mini-stats-wid card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Total Locations</p>
                                            <h4 className="mb-0">{chartsData?.data?.totallocation}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className="bx bx-map font-size-24">
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-3">
                            <div className="mini-stats-wid card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">New Providers</p>
                                            <h4 className="mb-0">{chartsData?.data?.newInstructor}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className="bx bx-user-circle font-size-24">
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mini-stats-wid card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Upcoming Classes</p>
                                            <h4 className="mb-0">{chartsData?.data?.upcomingClass}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className="bx bx-calendar-event font-size-24">
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mini-stats-wid card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Total Revenue</p>
                                            <h4 className="mb-0">${chartsData?.data?.totalTransaction}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className="bx bx-dollar-circle font-size-24">
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4 h4 card-title">Latest Transaction</div>
                                    <div className="table-responsive react-table">
                                        <table role="table" className="table table-bordered table-hover">
                                            <thead className="table-light table-nowrap">
                                                <tr role="row">
                                                    <th>
                                                        <div className="mb-2" title="Toggle SortBy" style={{ 'cursor': 'pointer' }}>Txn. Id</div>
                                                        <div style={{ 'marginTop': '5px' }}>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="mb-2" title="Toggle SortBy" style={{ 'cursor': 'pointer' }}>Amount</div>
                                                        <div style={{ 'marginTop': '5px' }}>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="mb-2" title="Toggle SortBy" style={{ 'cursor': 'pointer' }}>Date</div>
                                                        <div style={{ 'marginTop': '5px' }}>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="mb-2" title="Toggle SortBy" style={{ 'cursor': 'pointer' }}>Provider/User</div>
                                                        <div style={{ 'marginTop': '5px' }}>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="mb-2" title="Toggle SortBy" style={{ 'cursor': 'pointer' }}>Payment Status</div>
                                                        <div style={{ 'marginTop': '5px' }}>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody role="rowgroup">
                                                {chartsData?.data?.latestTransactions.map((f, i) => {
                                                    if (f?.isCredited == true) {
                                                        return (
                                                            <tr key={i + "-file"}>

                                                                <td role="cell">#{f?.id}</td>
                                                                <td role="cell">${f?.amount}</td>
                                                                <td role="cell">{moment(f?.createdAt).format('MM/DD/YYYY')}</td>
                                                                <td role="cell">{f?.userId?.fname} {f?.userId?.lname}</td>
                                                                <td role="cell" className="text-success text-center"><i className="bx bx-downvote font-size-24">
                                                                </i></td>
                                                            </tr>
                                                        )
                                                    } else {
                                                        return (
                                                            <tr key={i + "-file"}>

                                                                <td role="cell">#{f?.id}</td>
                                                                <td role="cell">${f?.amount}</td>
                                                                <td role="cell">{moment(f?.createdAt).format('MM/DD/YYYY')}</td>
                                                                <td role="cell">{f?.instructorId?.fname} {f?.instructorId?.lname}</td>
                                                                <td role="cell" className="text-danger  text-center"><i className="bx bx-upvote font-size-24">
                                                                </i>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                })}




                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Dashboard);


