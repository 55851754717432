import React, { Component } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 10px;
  text-align: left;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 1;
`;

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.clearSearchBox = this.clearSearchBox.bind(this);
  }
  state = {
    address:this.props.address
  }

  componentDidMount({ map, mapApi } = this.props) {
    const options = {
      // restrict your search to a specific type of result
      types: ["address"],
      // restrict your search to a specific country, or an array of countries
      // componentRestrictions: { country: ['gb', 'us'] },
    };
    this.autoComplete = new mapApi.places.Autocomplete(
      this.searchInput,
      options
    );
    // this.searchInput.value = "test";
    this.autoComplete.addListener("place_changed", this.onPlaceChanged);
    this.autoComplete.bindTo("bounds", map);
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  onPlaceChanged = ({ map, addplace } = this.props) => {
    const place = this.autoComplete.getPlace();

    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }
    console.log(place)

    addplace(place);
    this.setState({
      address:place.formatted_address
    })
    this.searchInput.blur();
  };

  clearSearchBox() {
    this.searchInput.value = "";
  }

  render() {
    return (
      <Wrapper>
        <input
          className="controls"
          ref={(ref) => {
            this.searchInput = ref;
          }}
          id="pac-input"
          type="text"
          //   onFocus={this.clearSearchBox}
          placeholder="Enter a location" 
          style={{width: '100%',
            padding: '0.47rem 0.75rem',
            fontSize: '0.8125rem',
            fontWeight: '400',
            lineHeight: '1.5',
            backgroundClip: 'padding-box',
            border: '1px solid lightgrey',
            appearance: 'none',
            borderRadius: '0.25rem',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'}}
            value={this.state.address}
            onChange={(e) => {
              this.setState({
                address:e.target.value
              })
            }}
        />
      </Wrapper>
    );
  }
}

export default AutoComplete;
