import React, { useEffect, useRef, useState } from "react"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    Label,
} from "reactstrap"
import * as Yup from "yup"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Field, Formik, Form } from "formik"
import { useHistory, useParams } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import {  editCategories, updateCategories } from "../../../store/categories/actions";
import { fireToast } from "../../../common/toast"
import cloud from "../../../assets/flix-images/cloud-file-download.svg"
import Dropzone from "react-dropzone"
import "./categories.css"
import { startLoader } from "../../../store/actions"
const CategoriesEdit = props => {

    const {singleCategories} = useSelector((state) => state.Categories )
    const uploadRef = useRef(null)
    //meta title
    document.title = "Edit Category"

    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const [data, setData] = useState({})
    const [image, setImage] = useState({
        blob: null,
        src: "",
    })
    const AddCategories = Yup.object().shape({
        name: Yup.string()
            .required("This field is required"),
        description: Yup.string()
            .required("This feild is required"),
    })
    // image validation
    const handleImageChange = files => {
        const extn = ["image/jpg", "image/png", "image/jpeg"]
        const [file] = files
        setData({ ...data, file })

        if (file && extn.includes(file.type)) {

            if (file.size <= 5242880) {
                setImage({ blob: file, src: window.URL.createObjectURL(file) })
            } else {
                fireToast("error", "image too large")
            }
        } else {
            fireToast(
                "error",
                "Please select a valid image file(only jpg, png and jpeg images are allowed)"
            )
        }
    }

    const handleSubmit = (e) => {
        console.log(data);
        dispatch(startLoader())

        dispatch(updateCategories(
            params.id,
            data
            , history))
    }
    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))

        setTokenHeader(data.access_token)
        dispatch(editCategories(params.id));
        console.log(singleCategories);
        
    }, [])

    useEffect(() => {
        if (singleCategories) {
          setData({ ...singleCategories })
        }
      }, [singleCategories])



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Categories" breadcrumbItem="Edit Category" />
                    <Formik
                        initialValues={{ ...data }}
                        validationSchema={AddCategories}
                        enableReinitialize
                        onSubmit={(e) => {
                            handleSubmit();
                            return false;
                        }}

                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Row>
                                <Col xl={8}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Name
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        value={data.name}
                                                        onChange={e => {
                                                            setData({ ...data, name: e.target.value })
                                                            setFieldValue('name', e.target.value)
                                                        }}
                                                    />
                                                    {errors.name && touched.name ? (
                                                        <div className="text-danger input-error">
                                                            {errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Description
                                                    </Label>
                                                    <Field
                                                        as="textarea"
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        rows="5"
                                                        value={data.description}
                                                        onChange={e => {
                                                            setData({ ...data, description: e.target.value })
                                                            setFieldValue('description', e.target.value)
                                                        }}
                                                    />
                                                    {errors.description && touched.description ? (
                                                        <div className="text-danger input-error">
                                                            {errors.description}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div >
                          <Label htmlFor="formrow-firstname-Input">
                            Image <small>(Optional)</small>
                          </Label>
                          <div className="text-center">

                            <div className="text-center">
                              <div className="mb-3 dragdrop-container">
                                <input
                                  ref={uploadRef}
                                  id="upload"
                                  hidden
                                  accept="image/*"
                                  type="file"
                                  onChange={e =>
                                    handleImageChange(e.target.files)
                                  }
                                />
                                {image.src ? (
                                  <div className="commonImgs">
                                    <img
                                      className="every-img"
                                      src={image.src ? image.src : cloud}
                                      alt=""
                                      onClick={() => {
                                        uploadRef.current.click()
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="drag-n-drop-container">
                                    <div>
                                      <Dropzone
                                        accept="image/*"
                                        multiple={false}
                                        onDrop={acceptedFiles => {
                                          handleImageChange(acceptedFiles)
                                        }}
                                      >
                                        {({
                                          getRootProps,
                                          getInputProps,
                                          isDragActive,
                                        }) => (
                                          <section>
                                            <div
                                              className="drop-area"
                                              {...getRootProps()}
                                            >
                                              <img
                                                width={60}
                                                src={cloud}
                                                alt=""
                                              />
                                              <input
                                                {...getInputProps()}
                                                accept="image/*"
                                                multiple={false}
                                              />

                                              {isDragActive ? (
                                                <div>
                                                  Drop your image file here
                                                </div>
                                              ) : (
                                                <p>
                                                  Drag n drop image file here,
                                                  or click to select <br />
                                                  <small className="text-center">
                                                    <strong>
                                                      Supported files:
                                                    </strong>{" "}
                                                    jpeg, jpg, png. | Will be
                                                    resized to: 1920x1080 px.
                                                  </small>
                                                </p>
                                              )}
                                            </div>
                                          </section>
                                        )}
                                      </Dropzone>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <p className="fw-bold">
                              Note:
                              <span className="text-danger mx-2 text-align-left">
                                Supported image formats are:&nbsp;jpg, png and
                                jpeg only
                              </span>
                            </p>
                          </div>
                        </div>
                        {singleCategories.image ? (<div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Uploaded
                          </Label>
                          <div>
                            <img
                              className="previousImage"
                              src={`${process.env.REACT_APP_ASSET_URL}${singleCategories.image}`}
                              alt=""
                              style={{maxHeight : '120px'}}
                            /></div>
                        </div>) : ('')}
                                                <div className="pt-5">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-md"
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    )
}


export default CategoriesEdit
