import {
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    ADD_USERS_SUCCESS,
    ADD_USERS_FAIL,
    DELETE_USERS_SUCCESS,
    DELETE_USERS_FAIL,
    EDIT_USERS_FAIL,
    EDIT_USERS_SUCCESS,
    UPDATE_USERS_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    users: {},
    error: {},
    singleUser: {}
};

const Users = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_USERS_SUCCESS:
            console.log('users ====> ', action.payload.data)
            return {
                ...state,
                users: action.payload.data
            };
        case GET_USERS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case ADD_USERS_SUCCESS:
            console.log('Add users ====> ', action.payload.data)
            return {
                ...state,
                users: {
                    ...state.data,
                    data: [...state.data, action.payload]
                },
            };
        case ADD_USERS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case EDIT_USERS_SUCCESS:
            console.log('EDIT users ====> ', action.payload.data)
            return {
                ...state,
                singleUser: action.payload.data
            };
        case EDIT_USERS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        

      case UPDATE_USERS_SUCCESS:

          return {
            ...state,
            users: {
              ...state.users,
              users: state.users.users.map(item => item?.id == action.payload.id ? action.payload : item)
            },
          }

        case DELETE_USERS_SUCCESS:
            console.log('DELETE users ====> ', action.payload.data)
            return {
                ...state,
                users: {
                    ...state.users,
                    users: [...state.users.users.filter(item => item['_id'] !== action.payload.data.id)]
                },
            };
        case DELETE_USERS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }

}


export default Users;