import { call, put, takeEvery} from "redux-saga/effects";

// Crypto Redux States
import { GET_CHARTS_DATA } from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";

//Include Both Helper File with needed methods
import {
    dashboarsApi
}
    from "../../helpers/backend_helper";
import { stopLoader } from "../loaderState/actions";

function* getChartsData({ payload: periodType }) {
    try {
        var response;
        response = yield call(dashboarsApi, periodType);
        yield put(apiSuccess(GET_CHARTS_DATA, response));
        yield put(stopLoader());
    } catch (error) {
        yield put(apiFail(GET_CHARTS_DATA, error));
    }
    yield put(stopLoader())

}

export function* watchGetChartsData() {
   
}

function* dashboardSaga() {
    yield takeEvery(GET_CHARTS_DATA, getChartsData);
}

export default dashboardSaga;
