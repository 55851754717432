import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { toast } from "react-toastify";
import { setTokenHeader } from "../../../helpers/api_helper";
import {
  postLogin,
} from "../../../helpers/backend_helper";

function* loginUser({ payload: { user: data, history } }) {
  try {
    const response = yield call(postLogin, data);
    if (response.status ==200) {
      toast.success(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      const { data } = response

      const user = {};
      user['access_token'] = data.token;
      user['email'] = data.data.email;
      user['name'] = 'Admin';
      user['role'] = 'Admin';
      console.log(user);
      yield put(loginSuccess(user))
      setTokenHeader(user.access_token)
      sessionStorage.setItem('authUser', JSON.stringify(user));
      history.push("/dashboard");
    } else {
      console.log(response);
      toast.error(response.message, {
        position: toast.POSITION.TOP_LEFT
      });
      yield put(apiError(response));
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_LEFT
    });
    yield put(apiError(error));
  }
}




function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export default authSaga;
