import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_LOCATIONS, ADD_LOCATIONS, DELETE_LOCATIONS, EDIT_LOCATIONS, UPDATE_LOCATIONS } from "./actionTypes";
import { getLocationsFail, getLocationsSuccess, addLocationsFail, addLocationsSuccess, deleteLocationsSuccess, deleteLocationsFail, editLocationsSuccess, editLocationsFail } from "./actions";

//Include Both Helper File with needed methods
import {
    getLocationsApi,
    addNewLocationsApi,
    deleteLocationsApi,
    editLocationsApi,
    updateLocationsApi
}
    from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { stopLoader } from "../loaderState/actions";

function* getLocationsData({ payload: {page , searchKeyword} }) {
    try {

        let response = yield call(getLocationsApi, page,searchKeyword);
        yield put(getLocationsSuccess(response));
    } catch (error) {
        yield put(getLocationsFail(error));
    }
    yield put(stopLoader())

}

function* addLocationsData({ payload: { reqData, history } }) {
    try {

        let classObj = {
            "name": reqData.data.name,
            "address": reqData.data.location,
            "description": reqData.data.description,
            "type": "Point",
            "coordinates": [reqData.data.lat, reqData.data.lng],
            "files":reqData.uploadedFiles
        };
        console.log("classObj : ", classObj)
        let response = yield call(addNewLocationsApi, classObj);

        if (response.data.status == 201) {
            yield put(getLocationsSuccess(response));

            toast.success("Location Added Successfully!!")

            history.push("/all-locations");
        } else {
            console.log('error message',response.message)
            toast.error(response.message)
        }

    } catch (error) {
        toast.error(error.message)
    }
    yield put(stopLoader())

}


function* editLocations({ payload: {id , history} }) {
    console.log('id : ' , id)
    try {

        let response = yield call(editLocationsApi, id);
        console.log("response : ", response);
        yield put(editLocationsSuccess(response));
        // history.push(`locations/${id}/edit`);
    } catch (error) {
        yield put(editLocationsFail(error));
    }
    yield put(stopLoader())

}

function* deleteLocations({ payload: id }) {
    try {
        const response = yield call(deleteLocationsApi, id.id)

        yield put(deleteLocationsSuccess(id))

    } catch (error) {
        yield put(deleteLocationsFail(error))
    }
    yield put(stopLoader())

}


function* onUpdateLocations({ payload: { reqData, history } }) {
    console.log(reqData)
    try {
        let classObj = {
            "name": reqData.data.name,
            "address": reqData.data.location,
            "description": reqData.data.description,
            "type": "Point",
            "coordinates": [reqData.data.lat ?reqData.data.lat :reqData.data.coordinates[0], reqData.data.lng?reqData.data.lng :reqData.data.coordinates[1]],
            "files":reqData.uploadedFiles.length ? reqData.uploadedFiles : reqData.data.locationImage
        };
        console.log("classObj : ", classObj)

        const response = yield call(updateLocationsApi, reqData.data.id, classObj)
        if (response.data.status == 200) {
            // yield put(getLocationsSuccess(response));

            toast.success("Location updated Successfully!!")

            history.push("/all-locations");
        } else {
            toast.error(response.message)
        }

        // yield put(updateLocationsSuccess(response.data))


    } catch (error) {
        console.log(error)
    }
}
    function* classesSaga() {
        yield takeEvery(GET_LOCATIONS, getLocationsData);
        yield takeEvery(ADD_LOCATIONS, addLocationsData);
        yield takeEvery(EDIT_LOCATIONS, editLocations);
        yield takeEvery(DELETE_LOCATIONS, deleteLocations);
        yield takeEvery(UPDATE_LOCATIONS, onUpdateLocations)
    }


    export default classesSaga;
