import React, { useEffect, useState } from "react"
import { cilPencil, cilTrash } from "@coreui/icons"
import Image from 'react-bootstrap/Image'

import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import {
    Row,
    Col,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory, Link } from "react-router-dom"
import { toastConfirm } from "../../../common/toast"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import NoImgFound from "../../../assets/No-image-found.jpg"
import { startLoader } from "../../../store/loaderState/actions";
import { deleteIncidents, getIncidents } from "../../../store/incidents/actions"

const Incidents = () => {
    document.title = "Admin | All Incidents";
    const [page, setPage] = useState(1);

    const [searchKeyword, setSearchKeyword] = useState([])
    const countPerPage = 10;
    const dispatch = useDispatch();
    const history = useHistory();
    const { incidents } = useSelector(state => state.Incidents)
    const deleteIncidentsWithId = async (id) => {
        const response = await (await toastConfirm("Are you sure you want to delete this?")).fire();
        if (response.isConfirmed) {
              dispatch(deleteIncidents( id , history))
        }
    }



    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
        dispatch(startLoader());
        dispatch(getIncidents())
        console.log(incidents);
    }, []);

    useEffect(() => {
        // dispatch(startLoader());
        dispatch(getIncidents(page,searchKeyword))
    }, [page,searchKeyword]);

    useEffect(() => {
        console.log(incidents);
    }, [incidents]);

    const columns = [
        {
            name: "Sr. no",
            selector: (row ,index)=> {
                if((incidents?.pagination?.page - 1) !=0){
                   
                    return ((incidents?.pagination?.page-1)*10) + (index+1)
               }
               return index+1
            },
            sortable: true,
        },
        {
            name: "Name",
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Actions",
            selector: row => (
                <>
                    <Link to={`incidents/${row._id}/edit`}>
                        <CIcon
                            icon={cilPencil}
                            className="text-warning hand me-2"
                        />
                    </Link>
                    <CIcon
                        icon={cilTrash}
                        className="text-danger hand"
                        onClick={() => {
                            deleteIncidentsWithId(row._id)
                        }}
                    />
                </>
            ),
        },

    ]

    return (
        <React.Fragment>
            <div className="page-content cast ">
                <div className="container-fluid">
                    <Breadcrumbs title="All Incident Categoires" breadcrumbItem="Manage Incident Categoires" />

                    <Row>
                        <Col xl={12}>
                            <div className="mb-2">
                                <Link to={'/all-incidents/add'} className="btn btn-primary w-md">Add +</Link>
                            </div>
                            <CCardGroup>
                                <CCard>
                                    <CCardBody>
                                    <div className="row">
                                            <div className="col-md-8">

                                            </div>
                                            <div className="col-md-4">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="search"
                                                    name="search"
                                                    placeHolder="Search Incident Categoires"
                                                    onChange={e => {
                                                        setSearchKeyword(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <DataTable
                                            striped
                                            columns={columns}
                                            data={incidents?.data}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={incidents?.pagination?.total}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={(page, totalRows) => {
                                                console.log('page : ', page);
                                                console.log('totalRows : ', totalRows);
                                                setPage(page)
                                            }}
                                        />
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                            

                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Incidents
