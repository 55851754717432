import './App.css';
import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import {authProtectedRoutes , normalRoutes} from "./routes"
import Authmiddleware from "./routes/route";
// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {normalRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
