import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

import Profile from "./auth/profile/reducer"

//E-commerce

//Calendar
import loadingState from "./loaderState/reducer"

//mails

//Dashboard 
import Dashboard from "./dashboard/reducer";
import Classes from "./classes/reducer";
import Users from "./users/reducer";
import Providers from "./providers/reducer";
import Locations from "./locations/reducer";
import Categories from "./categories/reducer";
import SplashScreen from "./splashScreen/reducer";
import Cms from "./cms/reducer";
import Incidents from "./incidents/reducer";
import Transactions from "./transactions/reducer";

//Dasboard saas

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Profile,
  loadingState,
  Dashboard,
  Classes,
  Users,
  Locations,
  Categories,
  Providers,
  SplashScreen,
  Transactions,
  Incidents,
  Cms
})

export default rootReducer
