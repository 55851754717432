import {
    GET_PROVIDERS,
    GET_PROVIDERS_SUCCESS,
    GET_PROVIDERS_FAIL,
    SETTLE_PROVIDER_PAYMENT,
    SETTLE_PROVIDER_PAYMENT_SUCCESS,
    SETTLE_PROVIDER_PAYMENT_FAIL,
    GET_NOT_VERIFIED_PROVIDERS,
    GET_NOT_VERIFIED_PROVIDERS_SUCCESS,
    GET_NOT_VERIFIED_PROVIDERS_FAIL,
    ADD_PROVIDERS,
    ADD_PROVIDERS_SUCCESS,
    ADD_PROVIDERS_FAIL,
   DELETE_PROVIDERS,
   DELETE_PROVIDERS_SUCCESS,
   DELETE_PROVIDERS_FAIL,
   EDIT_PROVIDERS_SUCCESS,
   EDIT_PROVIDERS,
   EDIT_PROVIDERS_FAIL,
   UPDATE_PROVIDERS,
   UPDATE_PROVIDERS_SUCCESS,
   UPDATE_PROVIDERS_FAIL,
   GET_PROVIDERS_DETAIL,
   GET_PROVIDERS_DETAIL_SUCCESS,
   GET_PROVIDERS_DETAIL_FAIL
} from "./actionTypes";

export const getProviders = (page = 1,searchKeyword='') => ({
    type: GET_PROVIDERS,
    payload: { page , searchKeyword},
});

export const getProvidersSuccess = (data) => ({
   
    type: GET_PROVIDERS_SUCCESS,
    payload: { data },
});

export const getProvidersFail = (error) => ({
    type: GET_PROVIDERS_FAIL,
    payload: { error },
});

export const getNotVerifiedProviders = (page = 1) => ({
    type: GET_NOT_VERIFIED_PROVIDERS,
    payload: { page },
});

export const getNotVerifiedProvidersSuccess = (data) => ({
   
    type: GET_NOT_VERIFIED_PROVIDERS_SUCCESS,
    payload: { data },
});

export const getNotVerifiedProvidersFail = (error) => ({
    type: GET_NOT_VERIFIED_PROVIDERS_FAIL,
    payload: { error },
});


export const addProviders = (data,history) => ({
    type: ADD_PROVIDERS,
    payload: { data ,history},
});

export const addProvidersSuccess = (data) => ({
   
    type: ADD_PROVIDERS_SUCCESS,
    payload: { data },
});

export const addProvidersFail = (error) => ({
    type: ADD_PROVIDERS_FAIL,
    payload: { error },
});
export const settleProviderPayment = (data ,callback) => ({
    type: SETTLE_PROVIDER_PAYMENT,
    payload: { data ,callback},
});

export const settleProviderPaymentSuccess = (data) => ({
   
    type: SETTLE_PROVIDER_PAYMENT_SUCCESS,
    payload: { data },
});

export const settleProviderPaymentFail = (error) => ({
    type: SETTLE_PROVIDER_PAYMENT_FAIL,
    payload: { error },
});

export const deleteProviders = (id) => ({
    type: DELETE_PROVIDERS,
    payload: { id},
});

export const deleteProvidersSuccess = (data) => ({
   
    type: DELETE_PROVIDERS_SUCCESS,
    payload: { data },
});

export const deleteProvidersFail = (error) => ({
    type: DELETE_PROVIDERS_FAIL,
    payload: { error },
});
export const editProviders = (id) => ({
    type: EDIT_PROVIDERS,
    payload: { id},
});

export const editProvidersSuccess = (data) => ({
   
    type: EDIT_PROVIDERS_SUCCESS,
    payload: { data },
});

export const editProvidersFail = (error) => ({
    type: EDIT_PROVIDERS_FAIL,
    payload: { error },
});

export const getProvidersDetail = (id) => ({
    type: GET_PROVIDERS_DETAIL,
    payload: { id},
});

export const getProvidersDetailSuccess = (data) => ({
   
    type: GET_PROVIDERS_DETAIL_SUCCESS,
    payload: { data },
});

export const getProvidersDetailFail = (error) => ({
    type: GET_PROVIDERS_DETAIL_FAIL,
    payload: { error },
});

export const updateProvidersSuccess = user => ({
    type: UPDATE_PROVIDERS_SUCCESS,
    payload: user,
  })
  
  export const updateProviders = (id , user,history) => ({
    type: UPDATE_PROVIDERS,
    payload: {id , user,history},
  })
  export const updateProvidersFail = error => ({
    type: UPDATE_PROVIDERS_FAIL,
    payload: error,
  })
