import React, { useEffect, useState } from "react"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    Label,
} from "reactstrap"
import * as Yup from "yup"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Field, Formik, Form } from "formik"
import { useHistory } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { addCms, getCms } from "../../../store/cms/actions"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { getCountryCallingCode} from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import "./../providers/providerStyle.css"
import { startLoader } from "../../../store/actions"



const ContactUs = props => {
    //meta title
    document.title = "Contact Us"


    const history = useHistory();
    const dispatch = useDispatch();
    const {cms} = useSelector((state) => state.Cms )
    const [data, setData] = useState({
        email: "",
        phoneNo:"",
    })

    const ContactUsValidation = Yup.object().shape({
        email: Yup.string()
            .min(4, "Too short!!")
            .max(1000, "Too Long!!")
            .required("This field is required"),
        phoneNo: Yup.string()
            .required("This field is required"),
    })


    const handleSubmit = (e) => {
        console.log(data);
        const obj = {
            'contactUs':{
                            'phoneNo':data?.phoneNo,
                            'email':data?.email,
                        }
        }
        console.log(obj)
        dispatch(startLoader());

        dispatch(addCms(
            obj
            , history))
    }
    useEffect(()=>{
        if(cms){
            setData({
                'email':cms?.cmsData?.contactUs.email,
                'phoneNo':cms?.cmsData?.contactUs.phoneNo,
            })
        }
    },[cms])

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
        dispatch(startLoader());

        dispatch(getCms())
        console.log(cms);
    }, [])
    


    return (
        <React.Fragment>
    

            <div className="page-content">
                <Container fluid={true}>
                
                    <Breadcrumbs title="Contact Us" breadcrumbItem="Contact Us" />
                    <Formik
                        initialValues={{ ...data }}
                        validationSchema={ContactUsValidation}
                        enableReinitialize
                        onSubmit={(e) => {
                            handleSubmit();
                            return false;
                        }}

                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Row>
                                <Col xl={8}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Email
                                                    </Label>
                                                    <Field
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        value={data.email}
                                                        onChange={e => {
                                                            setData({ ...data, email: e.target.value })
                                                            setFieldValue('email', e.target.value)
                                                        }}
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <div className="text-danger input-error">
                                                            {errors.email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Phone
                                                    </Label>
                                                    <PhoneInput flags={flags}
                                                    defaultCountry="US"
                                                        onCountryChange={(v) => {
                                                            setData({ ...data, countryCode: '+'+getCountryCallingCode(v) })
                                                            setFieldValue('countryCode','+'+getCountryCallingCode(v))
                                                        }}
                                                        placeholder="Enter phone number"
                                                        value={data.phoneNo}
                                                        onChange={ (setValue) =>{
                                                            if(setValue!= undefined){
                                                                setData({ ...data, phoneNo: setValue })
                                                                setFieldValue('phoneNo', setValue)
                                                            }
                                                        }}
                                                    />
                                                    {errors.phoneNo && touched.phoneNo ? (
                                                        <div className="text-danger input-error">
                                                            {errors.phoneNo}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="pt-5">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-md"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    )
}


export default ContactUs
