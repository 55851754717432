import {
    GET_CMS_SUCCESS,
    GET_CMS_FAIL,
    ADD_CMS_SUCCESS,
    ADD_CMS_FAIL,
    DELETE_CMS_SUCCESS,
    DELETE_CMS_FAIL,
    EDIT_CMS_FAIL,
    EDIT_CMS_SUCCESS,
    UPDATE_CMS_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    cms: {},
    error: {},
    singleCms: {}
};

const Cms = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_CMS_SUCCESS:
            return {
                ...state,
                cms: action.payload.data
            };
        case GET_CMS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case ADD_CMS_SUCCESS:
            return {
                ...state,
                cms: {
                    ...state.cms,
                    data: [...state.cms.data, action.payload]
                },
            };
        case ADD_CMS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case EDIT_CMS_SUCCESS:
            console.log('EDIT cms ====> ', action.payload.data.data)
            return {
                ...state,
                singleCms: action.payload.data.data
            };
        case EDIT_CMS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        

      case UPDATE_CMS_SUCCESS:

          return {
            ...state,
            cms: {
              ...state.cmsData,
              cmsData: state.cms.data.map(item => item?.id == action.payload.id ? action.payload : item)
            },
          }

        case DELETE_CMS_SUCCESS:
            console.log('DELETE cms ====> ', action.payload.data)
            return {
                ...state,
                cms: {
                    ...state.cms,
                    data: [...state.cms.data.filter(item => item['id'] !== action.payload.data.id)],
                    pagination:{...state.cms.pagination ,total:state.cms.pagination.total -1}
                },
            };
        case DELETE_CMS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }

}


export default Cms;