import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { GET_CLASSES, ADD_CLASSES, DELETE_CLASSES, EDIT_CLASSES, UPDATE_CLASSES, GET_CLASS_USERS, ARCHIVE_CLASSES } from "./actionTypes";
import { getClassesFail, getClassesSuccess, addClassesFail, addClassesSuccess, deleteClassesSuccess, deleteClassesFail, editClassesSuccess, editClassesFail, getClassUsersSuccess, getClassUsersFail, archiveClassesSuccess, archiveClassesFail } from "./actions";
import {
    getClassesApi,
    addNewClassesApi,
    deleteClassesApi,
    editClassesApi,
    updateClassesApi,
    getClassUsersApi,
    archiveClassesApi
}
    from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { stopLoader } from "../loaderState/actions";
import moment from "moment";

function* getClassesData({ payload: { filterData } }) {
    try {
        const keys = Object.keys(filterData)
        keys.map(key => {
            if (filterData[key] && !filterData[key].length && filterData[key] == '') {
                filterData[key] = null
            }
        });
        let response = yield call(getClassesApi, filterData);
        yield put(getClassesSuccess(response));
    } catch (error) {
        console.log("error : ", error);
        yield put(getClassesFail(error));
    }
    yield put(stopLoader())
}


function* getClassUsersData({ payload: { id } }) {
    try {
        let response = yield call(getClassUsersApi, id);
        yield put(getClassUsersSuccess(response));
    } catch (error) {
        yield put(getClassUsersFail(error));
    }
    yield put(stopLoader())
}


const changeTimeFormat = (data, times) => {

    // const date = moment(data).format("DD/MM/YYYY");
    // const converted = moment(times).format("hh:mm a");

    // return moment(date + ' ' + converted, 'DD/MM/YYYY hh:mm a');


    const time = moment(times)
    const date2 = moment(data);
    const converted = time.format("hh:mm a");
    const parsedTime = moment(converted, "hh:mm a");
    console.log();
    const startTime = date2.set({
        hour: parsedTime.hour(),
        minute: parsedTime.minute(),
        second: 0,
        millisecond: 0
    }).toISOString();
    return startTime;


}
function* addClassesData({ payload: { data, history } }) {
    try {
        console.log(data)

        let classObj = {
            "title": data.title,
            "date": data.date,
            "description": data.description,
            "startTime": changeTimeFormat(data.date, data.startTime),
            "endTime": changeTimeFormat(data.date, data.endTime),
            "duration": `${data.duration}`,
            "type": parseInt(data.type),
            "price": data.price,
            "totalSeat": data.totalSeat,
            'locationId': data.location._id,
            'categoryId': data.category._id ?? data.categoryId._id,
            'instructorId': data.instructor?._id ?? data.instructorId?._id,
            'file': data.file ? data.file : '',
            "classImage": data.classImage ? data.classImage : ''
        };
        console.log(classObj)
        let response = yield call(addNewClassesApi, classObj);
        if (response.data.status == 201) {
            yield put(getClassesSuccess(response));
            toast.success("Class Added Successfully!!")
            history.push("/all-classes");
        } else {
            toast.error(response.message)
        }
    } catch (error) {
        console.log(error);
        yield put(getClassesFail(error));
    }
    yield put(stopLoader())

}


function* editClasses({ payload: id }) {
    try {
        let response = yield call(editClassesApi, id.id);
        yield put(editClassesSuccess(response));
    } catch (error) {
        yield put(editClassesFail(error));
    }
    yield put(stopLoader())

}


function* onUpdateClasses({ payload: { id, classes, history } }) {
    try {
        let classObj = {
            "title": classes.title,
            "date": classes.date,
            "description": classes.description,
            "startTime": changeTimeFormat(classes.date, classes.startTime),
            "endTime": changeTimeFormat(classes.date, classes.endTime),
            "duration": `${classes.duration}`,
            "type": parseInt(classes.type),
            "price": classes.price,
            "totalSeat": classes.totalSeat,
            'location': classes.location._id,
            'categoryId': classes.categoryId._id,
            'instructorId': classes.instructorId._id,


        };
        if (classes.file != undefined && classes.file != '') {
            classObj.file = classes.file
        }
        const response = yield call(updateClassesApi, id, classObj)

        if (response?.data?.status == 200) {
            toast.success("Class updated Successfully!!")
            history.push("/all-classes");
        } else {
            toast.error(response.message)
        }

    } catch (error) {

    }
    yield put(stopLoader())

}

function* deleteClasses({ payload: id, history }) {
    try {
        const response = yield call(deleteClassesApi, id.id)
        if (response?.status == 200) {
            toast.success("Class Deleted Successfully!!")
            yield put(deleteClassesSuccess(id.id))
        } else {
            toast.error(response.message)
        }
        console.log(response);

    } catch (error) {
        yield put(deleteClassesFail(error))
    }

}


function* archiveClasses({ payload: id, history }) {
    try {
        const response = yield call(archiveClassesApi, id.id)
        if (response?.status == 200) {
            toast.success(response.message)
            yield put(archiveClassesSuccess(id.id))
        } else {
            toast.error(response.message)
        }
        console.log(response);

    } catch (error) {
        yield put(archiveClassesFail(error))
    }

}


function* classesSaga() {
    yield takeEvery(GET_CLASSES, getClassesData);
    yield takeEvery(ADD_CLASSES, addClassesData);
    yield takeEvery(GET_CLASS_USERS, getClassUsersData);
    yield takeEvery(EDIT_CLASSES, editClasses);
    yield takeEvery(DELETE_CLASSES, deleteClasses);
    yield takeEvery(ARCHIVE_CLASSES, archiveClasses);
    yield takeEvery(UPDATE_CLASSES, onUpdateClasses)
}


export default classesSaga;
