import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_SPLASH_SCREEN, ADD_SPLASH_SCREEN, DELETE_SPLASH_SCREEN, EDIT_SPLASH_SCREEN, UPDATE_SPLASH_SCREEN } from "./actionTypes";
import { getSplashScreenFail, getSplashScreenSuccess, addSplashScreenFail, addSplashScreenSuccess, deleteSplashScreenSuccess, deleteSplashScreenFail, editSplashScreenSuccess, editSplashScreenFail, updateSplashScreenSuccess, updateSplashScreenFail } from "./actions";

//Include Both Helper File with needed methods
import {
    getSplashScreenApi,
    addNewSplashScreenApi,
    deleteSplashScreenApi,
    editSplashScreenApi,
    updateSplashScreenApi
}
    from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { stopLoader } from "../loaderState/actions";

function* getSplashScreenData({ payload: page }) {
    try {

        let response = yield call(getSplashScreenApi, page.page);
        yield put(getSplashScreenSuccess(response));
    } catch (error) {
        yield put(getSplashScreenFail(error));
    }
    yield put(stopLoader())

}

function* addSplashScreenData({ payload: { data, history: handleClose } }) {
    try {
            var file = data.data

        let response = yield call(addNewSplashScreenApi, file);
        yield put(addSplashScreenSuccess(response.data));
        if(response.data.status ==201){
        console.log('response : ' ,handleClose)
        handleClose()
            // history.push('/all-splash-screen');
        }else{
            toast.error(response.message)
        }

    } catch (error) {
        yield put(getSplashScreenFail(error));
    }
    yield put(stopLoader())

}


function* editSplashScreen({ payload: id }) {
    try {

        let response = yield call(editSplashScreenApi, id.id);
        console.log(response);
        yield put(editSplashScreenSuccess(response));
    } catch (error) {
        yield put(editSplashScreenFail(error));
    }
    yield put(stopLoader())

}

function* onUpdateSplashScreen({ payload: { id,data, history: handleClose } }) {
    try {
        const response = yield call(updateSplashScreenApi, id, data)
        if(response.status ==200){
            console.log("response : " , response.data.data)
            yield put(updateSplashScreenSuccess(response.data.data))
        handleClose()

        }else{
            toast.error(response.message)
        }
    } catch (error) {
        yield put(updateSplashScreenFail(error))
    }
    yield put(stopLoader())

}

function* deleteSplashScreen({ payload: id ,history}) {
    try {
        const response = yield call(deleteSplashScreenApi, id.id)
        console.log(response)
        if(response.status =='201'){
        yield put(deleteSplashScreenSuccess(id))

        }else{
            toast.error(response.message)
        }


    } catch (error) {
        yield put(deleteSplashScreenFail(error))
    }
    yield put(stopLoader())

}

function* splashScreenSaga() {
    yield takeEvery(GET_SPLASH_SCREEN, getSplashScreenData);
    yield takeEvery(ADD_SPLASH_SCREEN, addSplashScreenData);
    yield takeEvery(EDIT_SPLASH_SCREEN, editSplashScreen);
    yield takeEvery(UPDATE_SPLASH_SCREEN, onUpdateSplashScreen)
    yield takeEvery(DELETE_SPLASH_SCREEN, deleteSplashScreen);
}


export default splashScreenSaga;
