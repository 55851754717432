import {
    GET_CLASSES,
    GET_CLASSES_SUCCESS,
    GET_CLASSES_FAIL,
    GET_CLASS_USERS,
    GET_CLASS_USERS_SUCCESS,
    GET_CLASS_USERS_FAIL,
    ADD_CLASSES,
    ADD_CLASSES_SUCCESS,
    ADD_CLASSES_FAIL,
    DELETE_CLASSES,
    DELETE_CLASSES_SUCCESS,
    DELETE_CLASSES_FAIL,
    ARCHIVE_CLASSES,
    ARCHIVE_CLASSES_SUCCESS,
    ARCHIVE_CLASSES_FAIL,
   EDIT_CLASSES_SUCCESS,
   EDIT_CLASSES,
   EDIT_CLASSES_FAIL,
   UPDATE_CLASSES_SUCCESS,
   UPDATE_CLASSES,
   UPDATE_CLASSES_FAIL
} from "./actionTypes";

export const getClasses = (filterData) => ({
    type: GET_CLASSES,
    payload: { filterData},
});

export const getClassesSuccess = (data) => ({
   
    type: GET_CLASSES_SUCCESS,
    payload: { data },
});

export const getClassesFail = (error) => ({
    type: GET_CLASSES_FAIL,
    payload: { error },
});


export const getClassUsers = (id) => ({
    type: GET_CLASS_USERS,
    payload: {id},
});

export const getClassUsersSuccess = (data) => ({
   
    type: GET_CLASS_USERS_SUCCESS,
    payload: { data },
});

export const getClassUsersFail = (error) => ({
    type: GET_CLASS_USERS_FAIL,
    payload: { error },
});



export const addClasses = (data,history) => ({
    type: ADD_CLASSES,
    payload: { data ,history},
});

export const addClassesSuccess = (data) => ({
   
    type: ADD_CLASSES_SUCCESS,
    payload: { data },
});

export const addClassesFail = (error) => ({
    type: ADD_CLASSES_FAIL,
    payload: { error },
});

export const deleteClasses = (id,history) => ({
    type: DELETE_CLASSES,
    payload: { id,history},
});

export const deleteClassesSuccess = (data) => ({
   
    type: DELETE_CLASSES_SUCCESS,
    payload: { data },
});

export const deleteClassesFail = (error) => ({
    type: DELETE_CLASSES_FAIL,
    payload: { error },
});


export const archiveClasses = (id,history) => ({
    type: ARCHIVE_CLASSES,
    payload: { id,history},
});

export const archiveClassesSuccess = (data) => ({
   
    type: ARCHIVE_CLASSES_SUCCESS,
    payload: { data },
});

export const archiveClassesFail = (error) => ({
    type: ARCHIVE_CLASSES_FAIL,
    payload: { error },
});
export const editClasses = (id) => ({
    type: EDIT_CLASSES,
    payload: { id},
});

export const editClassesSuccess = (data) => ({
   
    type: EDIT_CLASSES_SUCCESS,
    payload: { data },
});

export const editClassesFail = (error) => ({
    type: EDIT_CLASSES_FAIL,
    payload: { error },
});

export const updateClassesSuccess = classes => ({
    type: UPDATE_CLASSES_SUCCESS,
    payload: classes,
  })
  
  export const updateClasses = (id , classes,history) => ({
    type: UPDATE_CLASSES,
    payload: {id , classes,history},
  })
  export const updateClassesFail = error => ({
    type: UPDATE_CLASSES_FAIL,
    payload: error,
  })
