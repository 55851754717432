import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_CMS, ADD_CMS, DELETE_CMS, EDIT_CMS, UPDATE_CMS } from "./actionTypes";
import { getCmsFail, getCmsSuccess, addCmsFail, addCmsSuccess, deleteCmsSuccess, deleteCmsFail, editCmsSuccess, editCmsFail, updateCmsSuccess, updateCmsFail } from "./actions";

//Include Both Helper File with needed methods
import {
    getCmsApi,
    addNewCmsApi,
    deleteCmsApi,
    editCmsApi,
    updateCmsApi
}
    from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { stopLoader } from "../loaderState/actions";

function* getCmsData({ payload: page }) {
    try {

        let response = yield call(getCmsApi, page.page);
        yield put(getCmsSuccess(response));
    } catch (error) {
        yield put(getCmsFail(error));
    }
    yield put(stopLoader())

}

function* addCmsData({ payload: { data, history } }) {
    try {

        let response = yield call(addNewCmsApi, data);
        if(response.status ==200){
            toast.success('Updated Successfully.')
        }else{
            toast.error(response.message)
        }

    } catch (error) {
        yield put(getCmsFail(error));
    }
    yield put(stopLoader())

}


function* editCms({ payload: id }) {
    try {

        let response = yield call(editCmsApi, id.id);
        console.log(response);
        yield put(editCmsSuccess(response));
    } catch (error) {
        yield put(editCmsFail(error));
    }
    yield put(stopLoader())

}

function* onUpdateCms({ payload: cms }) {
    try {
        console.log(cms)
        const response = yield call(addNewCmsApi,cms)
        if(response.status ==200){
            toast.success('Updated Successfully.')
        }else{
            toast.error(response.message)
        }
    } catch (error) {
        yield put(updateCmsFail(error))
    }
    yield put(stopLoader())

}

function* deleteCms({ payload: id }) {
    try {
        const response = yield call(deleteCmsApi, id.id)
        if(response.status ==201){
        yield put(deleteCmsSuccess(id))
        // yield put(getCms());
        }else{
            toast.error(response.message)
        }

    } catch (error) {
        yield put(deleteCmsFail(error))
    }
    yield put(stopLoader())

}

function* cmsSaga() {
    yield takeEvery(GET_CMS, getCmsData);
    yield takeEvery(ADD_CMS, addCmsData);
    yield takeEvery(EDIT_CMS, editCms);
    yield takeEvery(UPDATE_CMS, onUpdateCms)
    yield takeEvery(DELETE_CMS, deleteCms);
}


export default cmsSaga;
