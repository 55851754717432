import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_INCIDENTS, ADD_INCIDENTS, DELETE_INCIDENTS, EDIT_INCIDENTS, UPDATE_INCIDENTS, GET_REPORTEDINCIDENTS } from "./actionTypes";
import { getIncidentsFail, getIncidentsSuccess, addIncidentsFail, addIncidentsSuccess, deleteIncidentsSuccess, deleteIncidentsFail, editIncidentsSuccess, editIncidentsFail, updateIncidentsSuccess, updateIncidentsFail, getReportedIncidentsSuccess, getReportedIncidentsFail } from "./actions";

//Include Both Helper File with needed methods
import {
    getIncidentsApi,
    addNewIncidentsApi,
    deleteIncidentsApi,
    editIncidentsApi,
    updateIncidentsApi,
    getReportedIncidentsApi
}
    from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { stopLoader } from "../loaderState/actions";

function* getIncidentsData({ payload: {page , searchKeyword} }) {
    try {

        let response = yield call(getIncidentsApi, page , searchKeyword);
        yield put(getIncidentsSuccess(response));
    } catch (error) {
        yield put(getIncidentsFail(error));
    }
    yield put(stopLoader())

}


function* getReportedIncidentsData({ payload: page }) {
    try {

        let response = yield call(getReportedIncidentsApi, page.page);
        yield put(getReportedIncidentsSuccess(response));
    } catch (error) {
        yield put(getReportedIncidentsFail(error));
    }
    yield put(stopLoader())

}
function* addIncidentsData({ payload: { data, history } }) {
    try {

        let response = yield call(addNewIncidentsApi, data);
        // yield put(getIncidentsSuccess(response));
        console.log('response : ' ,response)
        if(response.status ==200){
            toast.success('Added Successfully.')

            history.push('/all-incidents');
        }else{
            toast.error(response.message)
        }

    } catch (error) {
        yield put(getIncidentsFail(error));
    }
    yield put(stopLoader())

}


function* editIncidents({ payload: id }) {
    try {

        let response = yield call(editIncidentsApi, id.id);
        console.log(response);
        yield put(editIncidentsSuccess(response));
    } catch (error) {
        yield put(editIncidentsFail(error));
    }
    yield put(stopLoader())

}

function* onUpdateIncidents({ payload: { id, incidents, history } }) {
    try {
        console.log('incidents : ' , incidents)
        const response = yield call(updateIncidentsApi, id, incidents)
        if(response.status ==200){
            toast.success('Updated Successfully.')
            history.push('/all-incidents');
        }else{
            toast.error(response.message)
        }
    } catch (error) {
        yield put(updateIncidentsFail(error))
    }
    yield put(stopLoader())

}

function* deleteIncidents({ payload: id }) {
    try {
        const response = yield call(deleteIncidentsApi, id.id)
        if(response.status ==201){
        yield put(deleteIncidentsSuccess(id))
        toast.success('Deleted Successfully')
        }else{
            toast.error(response.message)
        }

    } catch (error) {
        yield put(deleteIncidentsFail(error))
    }
    yield put(stopLoader())

}

function* IncidentsSaga() {
    yield takeEvery(GET_INCIDENTS, getIncidentsData);
    yield takeEvery(GET_REPORTEDINCIDENTS, getReportedIncidentsData);
    yield takeEvery(ADD_INCIDENTS, addIncidentsData);
    yield takeEvery(EDIT_INCIDENTS, editIncidents);
    yield takeEvery(UPDATE_INCIDENTS, onUpdateIncidents)
    yield takeEvery(DELETE_INCIDENTS, deleteIncidents);
}


export default IncidentsSaga;
