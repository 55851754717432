import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from "react-router-dom";
import {ToastContainer, toast} from "react-toastify"
import { Provider } from "react-redux";

import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
      <ToastContainer />
    </Provider>
);

