import {
  START_LOADER,
  STOP_LOADER,
  START_LOADER_SUCCESS,
  STOP_LOADER_SUCCESS,
} from "./actionTypes"

export const startLoader = () => ({
  type: START_LOADER,
})
export const stopLoader = () => ({
  type: STOP_LOADER,
})
export const startLoaderSuccess = User => ({
  type: START_LOADER_SUCCESS,
})
export const stopLoaderSuccess = User => ({
  type: STOP_LOADER_SUCCESS,
})



