import React, { Component, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import NoImgFound from "../../../assets/No-image-found.jpg"
import { map } from "lodash";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getProvidersDetail, settleProviderPayment, startLoader, stopLoader } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { setTokenHeader } from "../../../helpers/api_helper";
import moment from "moment";



const ProviderView = () => {
  const { singleProvider } = useSelector((state) => state.Providers)
  const dispatch = useDispatch();
  const params = useParams();
  const [isDisabledState,setIsDisabledState] = useState(false);
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"))
    setTokenHeader(data.access_token)
    dispatch(stopLoader());
    dispatch(getProvidersDetail(params.id))
  }, []);

  useEffect(() => {
    console.log('signle provider : ', singleProvider)
  }, [singleProvider])
  //meta title
  document.title = "Profile | Skote - React Admin & Dashboard Template";
  
  const PayRestAmount = () => {
      setIsDisabledState(true);
        let PaymentObj = {
          "instructorId" :singleProvider?.data?.users?._id,
          "amount" :singleProvider?.data?.users?.total_Earning,
          "note" :"payment Added",
        }
        const callback = () =>{
          setIsDisabledState(false)
        }
      dispatch(settleProviderPayment(PaymentObj ,callback))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Instructor" breadcrumbItem={singleProvider?.data?.users?.name} />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  
                </div>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <div className="  mb-4">
                        

                      </div>
                      <h5 className="font-size-15 text-truncate text-center" >
                        {singleProvider?.data?.users?.name} ( Instructor)
                      </h5>
                    </Col>

                    <Col sm={12}>
                    {
                          (singleProvider?.data?.users?.userProfile != null || singleProvider?.data?.users?.userProfile != '') ?
                            (
                              <img
                                src={`${process.env.REACT_APP_ASSET_URL}${singleProvider?.data?.users?.userProfile}`}
                                alt=""
                                className="img-thumbnail "
                              />
                            ) : (
                              <img
                                src={NoImgFound}
                                alt=""
                                className="img-thumbnail rounded-circle"
                              />
                            )
                        }
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4 h4">
                    Personal Information
                  </CardTitle>

                  <p className="text-muted mb-4">
                    {singleProvider?.data?.users?.personalDetail}
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{singleProvider?.data?.users?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{singleProvider?.data?.users?.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{singleProvider?.data?.users?.email}</td>
                        </tr>
                        {/* <tr>
                          <th scope="row">Location :</th>
                          <td>{singleProvider?.data?.users?.location.name}</td>
                        </tr> */}
                        <tr>
                          <th scope="row">Session Rate :</th>
                          <td>{singleProvider?.data?.users?.sessionRate}</td>
                        </tr>
                        <tr>
                          <th scope="row">Gender :</th>
                          <td>{(singleProvider?.data?.users?.gender == 1) ? 'Male' : 'Female'}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

            </Col>
            <Col xl="8">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Total Classes
                          </p>
                          <h4 className="mb-0">{singleProvider?.data?.class?.length}</h4>
                        </div>

                        <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i
                              className={"bx font-size-24"}
                            />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Total Earning
                          </p>
                          <h4 className="mb-0"> $ {singleProvider?.data?.users?.total_Earning}</h4>
                        </div>

                        <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                          <span className="avatar-title">
                            <i
                              className={"bx bx bx-dollar-circle font-size-24"}
                            />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium mb-2">
                            Pending Payment
                          </p>
                          <h4 className="mb-0 txt-danger">$ {singleProvider?.data?.users?.total_Balance > 0 ? singleProvider?.data?.users?.total_Balance : 0}</h4>
                          
                        </div>
                        {singleProvider?.data?.users?.total_Balance > 0 ? (<div 
                        className="align-self-center bg-primary border round">
                          <button onClick={()=>{
                            PayRestAmount()
                          }} 
                          className="btn btn-primary" disabled={isDisabledState}>Pay</button>
                        </div>) : ('')}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4 h4">Classes</CardTitle>
                  <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Class Name</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Location</th>
                          </tr>
                        </thead>
                        <tbody>
                        {singleProvider?.data?.class?.map((f, i) => {
                          return (
                            <tr key={i + "-file"}>
                              <th scope="row">{i +1}</th>
                              <td>{f?.title}</td>
                              <td>{moment(f?.date).format('MM/DD/YYYY')}</td>
                              <td>{moment(f?.startTime).format('hh:mm a').toLocaleString()} - {moment(f?.endTime).format('hh:mm a').toLocaleString()}</td>
                              <td>{f?.location?.name}</td>
                            </tr>
                          )
                        })}

                         
                        </tbody>
                      </Table>
                    </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4 h4">Transaction</CardTitle>
                  <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Amount</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                        {singleProvider?.data?.transaction?.map((f, i) => {
                          return (
                            <tr key={i + "-file"}>
                              <th scope="row">#{i + 1}</th>
                              <td>${f?.amount}</td>
                              <td>{moment(f?.createdAt).format('MM/DD/YYYY')}</td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </Table>
                    </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ProviderView;
