import React, { useEffect, useRef, useState } from "react"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    Label,
} from "reactstrap"
import * as Yup from "yup"
import MyGoogleMap from "../GoogleMap/MyGoogleMap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Formik, Form, Field } from "formik"
import { Link, useHistory, useParams } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { editLocations, editLocationsSuccess, updateLocations } from "../../../store/locations/actions";
import axios from "axios";
import cloud from "../../../assets/flix-images/cloud-file-download.svg"
import Dropzone from "react-dropzone"
import { fireToast } from "../../../common/toast"

import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";



const LocationsEdit = props => {


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        console.log('result : ', result)
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function Quote({ quote, index }) {
        return (
            <Draggable draggableId={quote} index={index}>
                {provided => (
                    <div style={{
                        "width": "200px",
                        "margin": "10px"
                    }} className="p-2 crossOuterDiv"
                        key={index + "-file"}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <span class="DeleteUploadedImage" onClick={() => {
                            DeleteImage(quote)
                        }}><i className="bx bx-plus"></i></span>
                        <img
                            data-dz-thumbnail=""
                            height="120"
                            className="avatar-lg rounded bg-light"
                            alt={quote}
                            src={`${process.env.REACT_APP_ASSET_URL}${quote}`}
                        />
                    </div>
                )}
            </Draggable>
        );
    }
    const QuoteList = React.memo(function QuoteList({ quotes }) {
        console.log('quotes   : ', quotes)

        return quotes.map((value, index) => (
            <Quote quote={value} index={index} key={value} />
        ));
    });


    const DeleteImage = (image) => {
        console.log('image : ', image)
        setData({ ...data, locationImage: data.locationImage.filter(item => item !== image) })
    }
    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const quotes = reorder(
            data.locationImage,
            result.source.index,
            result.destination.index
        );

        setData({ ...data, locationImage: quotes });
    }

    const uploadRef = useRef(null)
    const [selectedFiles, setSelectedFiles] = useState([])
    //meta title
    document.title = "Edit Location"
    const { singleLocation } = useSelector((state) => state.Locations)

    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const [data, setData] = useState({
        location: "",
        lat: "",
        lng: "",
        name: singleLocation?.name,
        description: singleLocation?.description,
        files: [],
        locationImage: [],
    })


    const editLocation = Yup.object().shape({
        // lat: Yup.string().required(),
        // lng: Yup.string().required(),
        // location: Yup.string().required(),
        // name: Yup.string().required(),
        // filteredFiles: Yup.array().required()
    })

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const handleSubmit = async (e) => {
        console.log(data);
        const uploadedFiles = await UploadFiles(data?.filteredFiles);
        console.log(" handleSubmit uploadedFiles : ", uploadedFiles);
        await delay(1000);
        dispatch(updateLocations(
            { data, uploadedFiles },
            history))
    }
    const UploadFiles = (Files = []) => {
        let UploadedFiles = [];

        if (!Files.length) {
            const newArr = [...UploadedFiles, ...data.locationImage]
            return newArr;
        }
        return new Promise(async (resolve, reject) => {
            Files.map(async function (file, index) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(sessionStorage.getItem("authUser")).access_token}`;
                axios.post(`${process.env.REACT_APP_ALL_OUT_FTINESS_URL}/test`, { file }, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(function (response) {
                        UploadedFiles.push(response.data.data);
                        console.log('response : ', response.data.data)
                    })
                    .catch(function (error) {
                        console.log('error : ', error.response.message)
                    }).finally(function () {
                        if (index == Files.length - 1) {
                            const newArr = [...UploadedFiles, ...data.locationImage]
                            console.log("newArr : ", newArr);
                            resolve(newArr);
                        }
                    });
            })
        })
    };

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))

        setTokenHeader(data.access_token)
        dispatch(editLocations(
            params.id))

        return () => {
            dispatch(editLocationsSuccess({
                data: {
                    "_id": "64b8d8df3c03722fef0677f7",
                    "locationImage": [],
                    "name": "",
                    "address": "",
                    "type": "Point",
                    "coordinates": [
                        32.7819582,
                        -96.8083044
                    ],
                    "lng": -96.80818279269077,
                    "lat": 32.7818751838611,
                    // "createdAt": "2023-07-20T06:49:03.406Z",
                    // "updatedAt": "2023-07-21T14:02:34.895Z",
                    // "__v": 5,
                    "id": "64b8d8df3c03722fef0677f7"
                }
            }))
        }
    }, [])

    useEffect(() => {
        if (singleLocation?.name)
            setData({ ...singleLocation })

    }, [singleLocation])

    useEffect(() => {
        console.log('data-------------------', data)

    }, [data])


    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    const getLatLng = async (lat, lng, placeObj = '') => {
        console.log(lat, lng, placeObj)
        if (placeObj !== '') {
            setData({ ...data, ...singleLocation, location: placeObj.formatted_address, lat: lat, lng: lng })
        } else {
            try {
                let response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDvCd54TfWJRxnwGDbC-iLcwwADlGQrFmA&sensor=true`);
                console.log(response.data.results[0].formatted_address)
                setData({ ...data, ...singleLocation, location: response.data.results[0].formatted_address, lat: lat, lng: lng })
            } catch (error) {

            }
        }
    }
    const handleAcceptedFiles = filesData => {
        const extn = ["image/jpg", "image/png", "image/jpeg"]
        // console.log(files);
        const filteredFiles = [];
        const files = filesData.filter(file => {
            if (file && extn.includes(file.type)) {

                if (file.size <= 5242880) {
                    console.log(file);

                    filteredFiles.push(file)
                    return Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        formattedSize: formatBytes(file.size),
                    })
                } else {
                    fireToast("error", "image too large")
                }
            } else {
                fireToast(
                    "error",
                    "Please select a valid image file(only jpg, png and jpeg images are allowed)"
                )
            }
        }

        )

        setSelectedFiles(files)
        setData({ ...data, filteredFiles })

    }

    // useEffect(() => {
    //     console.log("name : ", name);
    // }, [name])

    useEffect(() => {
        console.log('data ================ > ', data)
    }, [data])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Location" breadcrumbItem="Edit Location" />
                    <Formik
                        initialValues={{ ...data }}
                        validationSchema={editLocation}
                        enableReinitialize
                        onSubmit={(e) => {
                            handleSubmit();
                            return false;
                        }}

                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Row>
                                <Col xl={8}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Name
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        value={data?.name}
                                                        // value={name}
                                                        onChange={e => {
                                                            setData({ ...data, name: e.target.value })
                                                            setFieldValue('name', e.target.value)
                                                        }}
                                                    />
                                                    {errors.name && touched.name ? (
                                                        <div className="text-danger input-error">
                                                            {errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Description
                                                    </Label>
                                                    <Field
                                                        as="textarea"
                                                        className="form-control"
                                                        id="description"
                                                        name="description"
                                                        rows="5"
                                                        onChange={e => {
                                                            setData({ ...data, description: e.target.value })
                                                            setFieldValue('description', e.target.value)
                                                        }}
                                                    />
                                                    {errors.description && touched.description ? (
                                                        <div className="text-danger input-error">
                                                            {errors.description}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div style={{ 'height': '400px', 'position': 'relative' }} className="mb-3 col-md-12">
                                                    <Label htmlFor="formrow-firstname-Input" className="d-block mb-0 pb-0">
                                                        Location

                                                    </Label>
                                                    <span className="text-danger text-center "> <small>Location services for this url must be authorized on current browser for map to function properly</small>
                                                    </span>
                                                    {(Object.keys(singleLocation).length != 0 && singleLocation?.name.length) ? (<MyGoogleMap getLatLng={getLatLng} singleLocation={singleLocation} />) : (<p>Waiting.... {console.log('.loading...')}</p>)}

                                                </div><hr></hr>
                                                {errors.location && touched.location ? (
                                                    <div className="text-danger input-error">
                                                        {errors.location}
                                                    </div>
                                                ) : null}
                                                <div className="mb-3 col-md-12 pt-5">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Images <small>(minimum 1 Image required)</small>
                                                    </Label>
                                                    <div className="text-center">

                                                        <div className="text-center">
                                                            <div className="mb-3 dragdrop-container">
                                                                <input
                                                                    ref={uploadRef}
                                                                    id="upload"
                                                                    hidden
                                                                    accept="image/*"
                                                                    type="file"
                                                                    multiple={true}
                                                                    onChange={e =>
                                                                        handleAcceptedFiles(e.target.files)
                                                                    }
                                                                />

                                                                <div className="drag-n-drop-container">
                                                                    <div>
                                                                        <Dropzone
                                                                            accept="image/*"
                                                                            multiple={true}
                                                                            // onDrop={acceptedFiles => {
                                                                            //     handleImageChange(acceptedFiles)
                                                                            // }}
                                                                            onDrop={acceptedFiles =>
                                                                                handleAcceptedFiles(acceptedFiles)
                                                                            }
                                                                        >
                                                                            {({
                                                                                getRootProps,
                                                                                getInputProps,
                                                                                isDragActive,
                                                                            }) => (
                                                                                <section>
                                                                                    <div
                                                                                        className="drop-area"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <img
                                                                                            width={60}
                                                                                            src={cloud}
                                                                                            alt=""
                                                                                        />
                                                                                        <input
                                                                                            {...getInputProps()}
                                                                                            accept="images/*"
                                                                                            multiple={true}
                                                                                        />

                                                                                        {isDragActive ? (
                                                                                            <div>
                                                                                                Drop your image file here
                                                                                            </div>
                                                                                        ) : (
                                                                                            <p>
                                                                                                Drag n drop image file here,
                                                                                                or click to select <br />
                                                                                                <small className="text-center">
                                                                                                    <strong>
                                                                                                        Supported files:
                                                                                                    </strong>{" "}
                                                                                                    jpeg, jpg, png. | Will be
                                                                                                    resized to: 1920x1080 px.
                                                                                                </small>
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </section>
                                                                            )}
                                                                        </Dropzone>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="fw-bold">
                                                            Note:
                                                            <span className="text-danger mx-2 text-align-left">
                                                                Supported image formats are:&nbsp;jpg, png and
                                                                jpeg only
                                                            </span>
                                                        </p>
                                                        <div className="dropzone-previews mt-3"
                                                            id="file-previews">
                                                            {selectedFiles.map((f, i) => {
                                                                return (
                                                                    <Card
                                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                        key={i + "-file"}
                                                                    >
                                                                        <div className="p-2">
                                                                            <Row className="align-items-center">
                                                                                <Col className="col-auto">
                                                                                    <img
                                                                                        data-dz-thumbnail=""
                                                                                        height="80"
                                                                                        className="avatar-sm rounded bg-light"
                                                                                        alt={f.name}
                                                                                        src={f.preview}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <Link
                                                                                        to="#"
                                                                                        className="text-muted font-weight-bold"
                                                                                    >
                                                                                        {f.name}
                                                                                    </Link>
                                                                                    <p className="mb-0">
                                                                                        <strong>{f.formattedSize}</strong>
                                                                                    </p>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Card>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    {errors.files && touched.files ? (
                                                        <div className="text-danger input-error">
                                                            {errors.files}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="dropzone-previews mt-3"
                                                    id="file-previews-uploaded">
                                                    <p>Last Updloaded Images</p>
                                                    {(data?.locationImage.length != 0) ? (
                                                        <DragDropContext onDragEnd={onDragEnd}>
                                                            <Droppable droppableId="list">
                                                                {provided => (
                                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                                        <QuoteList quotes={data?.locationImage} />
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>) : (<p>Waiting.... {console.log('.loading...')}</p>)}



                                                </div>
                                                <div className="pt-5">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-md"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    )
}


export default LocationsEdit
