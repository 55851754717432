import React, { useEffect, useRef, useState } from "react"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    Label,
} from "reactstrap"
import * as Yup from "yup"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Field, Formik, Form } from "formik"
import { useHistory } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { useDispatch } from "react-redux";
import { addIncidents, startLoader } from "../../../store/actions"
const IncidentsAdd = props => {
    //meta title
    document.title = "Add Incident"

    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        name:'',
    })
  
    const AddIncidents = Yup.object().shape({
        name: Yup.string()
            .required("This field is required"),
    })
    
    const handleSubmit = (e) => {
        console.log(data);
        dispatch(startLoader());
        
        dispatch(addIncidents(
            data
            , history))
    }
    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
        
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Incidents" breadcrumbItem="Add Incident" />
                    <Formik
                        initialValues={{ ...data }}
                        validationSchema={AddIncidents}
                        enableReinitialize
                        onSubmit={(e) => {
                            handleSubmit();
                            return false;
                        }}

                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Row>
                                <Col xl={8}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Name
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        onChange={e => {
                                                            setData({ ...data, name: e.target.value })
                                                            setFieldValue('name', e.target.value)
                                                        }}
                                                    />
                                                    {errors.name && touched.name ? (
                                                        <div className="text-danger input-error">
                                                            {errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="pt-5">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-md"
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    )
}


export default IncidentsAdd
