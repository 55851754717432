/** Get Dashboard Chart data */
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";


/** Get Dashboard Chart data */
export const GET_USER_TRANSACTIONS = "GET_USER_TRANSACTIONS";
export const GET_USER_TRANSACTIONS_SUCCESS = "GET_USER_TRANSACTIONS_SUCCESS";
export const GET_USER_TRANSACTIONS_FAIL = "GET_USER_TRANSACTIONS_FAIL";


export const ADD_TRANSACTIONS = "ADD_TRANSACTIONS";
export const ADD_TRANSACTIONS_SUCCESS = "ADD_TRANSACTIONS_SUCCESS";
export const ADD_TRANSACTIONS_FAIL = "ADD_TRANSACTIONS_FAIL";

export const DELETE_TRANSACTIONS = "DELETE_TRANSACTIONS";
export const DELETE_TRANSACTIONS_SUCCESS = "DELETE_TRANSACTIONS_SUCCESS";
export const DELETE_TRANSACTIONS_FAIL = "DELETE_TRANSACTIONS_FAIL";

export const EDIT_TRANSACTIONS = "EDIT_TRANSACTIONS";
export const EDIT_TRANSACTIONS_SUCCESS = "EDIT_TRANSACTIONS_SUCCESS";
export const EDIT_TRANSACTIONS_FAIL = "EDIT_TRANSACTIONS_FAIL";

export const UPDATE_TRANSACTIONS = "UPDATE_TRANSACTIONS"
export const UPDATE_TRANSACTIONS_SUCCESS = "UPDATE_TRANSACTIONS_SUCCESS"
export const UPDATE_TRANSACTIONS_FAIL = "UPDATE_TRANSACTIONS_FAIL"