export const GET_INCIDENTS = "GET_INCIDENTS";
export const GET_INCIDENTS_SUCCESS = "GET_INCIDENTS_SUCCESS";
export const GET_INCIDENTS_FAIL = "GET_INCIDENTS_FAIL";

export const GET_REPORTEDINCIDENTS = "GET_REPORTEDINCIDENTS";
export const GET_REPORTEDINCIDENTS_SUCCESS = "GET_REPORTEDINCIDENTS_SUCCESS";
export const GET_REPORTEDINCIDENTS_FAIL = "GET_REPORTEDINCIDENTS_FAIL";

export const ADD_INCIDENTS = "ADD_INCIDENTS";
export const ADD_INCIDENTS_SUCCESS = "ADD_INCIDENTS_SUCCESS";
export const ADD_INCIDENTS_FAIL = "ADD_INCIDENTS_FAIL";

export const DELETE_INCIDENTS = "DELETE_INCIDENTS";
export const DELETE_INCIDENTS_SUCCESS = "DELETE_INCIDENTS_SUCCESS";
export const DELETE_INCIDENTS_FAIL = "DELETE_INCIDENTS_FAIL";

export const EDIT_INCIDENTS = "EDIT_INCIDENTS";
export const EDIT_INCIDENTS_SUCCESS = "EDIT_INCIDENTS_SUCCESS";
export const EDIT_INCIDENTS_FAIL = "EDIT_INCIDENTS_FAIL";

export const UPDATE_INCIDENTS = "UPDATE_INCIDENTS"
export const UPDATE_INCIDENTS_SUCCESS = "UPDATE_INCIDENTS_SUCCESS"
export const UPDATE_INCIDENTS_FAIL = "UPDATE_INCIDENTS_FAIL"