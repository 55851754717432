import {
    GET_SPLASH_SCREEN,
    GET_SPLASH_SCREEN_SUCCESS,
    GET_SPLASH_SCREEN_FAIL,
    ADD_SPLASH_SCREEN,
    ADD_SPLASH_SCREEN_SUCCESS,
    ADD_SPLASH_SCREEN_FAIL,
   UPDATE_SPLASH_SCREEN,
   UPDATE_SPLASH_SCREEN_SUCCESS,
   UPDATE_SPLASH_SCREEN_FAIL,
   DELETE_SPLASH_SCREEN,
   DELETE_SPLASH_SCREEN_SUCCESS,
   DELETE_SPLASH_SCREEN_FAIL,
   EDIT_SPLASH_SCREEN_SUCCESS,
   EDIT_SPLASH_SCREEN,
   EDIT_SPLASH_SCREEN_FAIL
} from "./actionTypes";

export const getSplashScreen = (page = 1) => ({
    type: GET_SPLASH_SCREEN,
    payload: { page },
});

export const getSplashScreenSuccess = (data) => ({
   
    type: GET_SPLASH_SCREEN_SUCCESS,
    payload: { data },
});

export const getSplashScreenFail = (error) => ({
    type: GET_SPLASH_SCREEN_FAIL,
    payload: { error },
});


export const addSplashScreen = (data,history) => ({
    type: ADD_SPLASH_SCREEN,
    payload: { data ,history},
});

export const addSplashScreenSuccess = (data) => ({
   
    type: ADD_SPLASH_SCREEN_SUCCESS,
    payload: { data },
});

export const addSplashScreenFail = (error) => ({
    type: ADD_SPLASH_SCREEN_FAIL,
    payload: { error },
});

export const deleteSplashScreen = (id,history) => ({
    type: DELETE_SPLASH_SCREEN,
    payload: { id , history },
});

export const deleteSplashScreenSuccess = (data) => ({
   
    type: DELETE_SPLASH_SCREEN_SUCCESS,
    payload: { data },
});

export const deleteSplashScreenFail = (error) => ({
    type: DELETE_SPLASH_SCREEN_FAIL,
    payload: { error },
});
export const editSplashScreen = (id) => ({
    type: EDIT_SPLASH_SCREEN,
    payload: { id},
});

export const editSplashScreenSuccess = (data) => ({
   
    type: EDIT_SPLASH_SCREEN_SUCCESS,
    payload: { data },
});

export const editSplashScreenFail = (error) => ({
    type: EDIT_SPLASH_SCREEN_FAIL,
    payload: { error },
});

export const updateSplashScreenSuccess = (splashScreen) => ({
    type: UPDATE_SPLASH_SCREEN_SUCCESS,
    payload: (splashScreen),
  })
  
  export const updateSplashScreen = (id ,data,history) => ({
    type: UPDATE_SPLASH_SCREEN,
    payload: {id ,data,history},
  })
  export const updateSplashScreenFail = error => ({
    type: UPDATE_SPLASH_SCREEN_FAIL,
    payload: error,
  })
