import {
    GET_TRANSACTIONS,
    GET_TRANSACTIONS_SUCCESS,
    GET_TRANSACTIONS_FAIL,
    GET_USER_TRANSACTIONS,
    GET_USER_TRANSACTIONS_SUCCESS,
    GET_USER_TRANSACTIONS_FAIL,
    ADD_TRANSACTIONS,
    ADD_TRANSACTIONS_SUCCESS,
    ADD_TRANSACTIONS_FAIL,
   UPDATE_TRANSACTIONS,
   UPDATE_TRANSACTIONS_SUCCESS,
   UPDATE_TRANSACTIONS_FAIL,
   DELETE_TRANSACTIONS,
   DELETE_TRANSACTIONS_SUCCESS,
   DELETE_TRANSACTIONS_FAIL,
   EDIT_TRANSACTIONS_SUCCESS,
   EDIT_TRANSACTIONS,
   EDIT_TRANSACTIONS_FAIL
} from "./actionTypes";

export const getTransactions = (filterData) => ({
    type: GET_TRANSACTIONS,
    payload: { filterData },
});

export const getTransactionsSuccess = (data) => ({
   
    type: GET_TRANSACTIONS_SUCCESS,
    payload: { data },
});

export const getTransactionsFail = (error) => ({
    type: GET_TRANSACTIONS_FAIL,
    payload: { error },
});


export const getUserTransactions = (filterData) => ({
    type: GET_USER_TRANSACTIONS,
    payload: { filterData },
});

export const getUserTransactionsSuccess = (data) => ({
   
    type: GET_USER_TRANSACTIONS_SUCCESS,
    payload: { data },
});

export const getUserTransactionsFail = (error) => ({
    type: GET_USER_TRANSACTIONS_FAIL,
    payload: { error },
});



export const addTransactions = (data,history) => ({
    type: ADD_TRANSACTIONS,
    payload: { data ,history},
});

export const addTransactionsSuccess = (data) => ({
   
    type: ADD_TRANSACTIONS_SUCCESS,
    payload: { data },
});

export const addTransactionsFail = (error) => ({
    type: ADD_TRANSACTIONS_FAIL,
    payload: { error },
});

export const deleteTransactions = (id,history) => ({
    type: DELETE_TRANSACTIONS,
    payload: { id ,history},
});

export const deleteTransactionsSuccess = (data) => ({
   
    type: DELETE_TRANSACTIONS_SUCCESS,
    payload: { data },
});

export const deleteTransactionsFail = (error) => ({
    type: DELETE_TRANSACTIONS_FAIL,
    payload: { error },
});
export const editTransactions = (id) => ({
    type: EDIT_TRANSACTIONS,
    payload: { id},
});

export const editTransactionsSuccess = (data) => ({
   
    type: EDIT_TRANSACTIONS_SUCCESS,
    payload: { data },
});

export const editTransactionsFail = (error) => ({
    type: EDIT_TRANSACTIONS_FAIL,
    payload: { error },
});

export const updateTransactionsSuccess = cms => ({
    type: UPDATE_TRANSACTIONS_SUCCESS,
    payload: cms,
  })
  
  export const updateTransactions = (cms) => ({
    type: UPDATE_TRANSACTIONS,
    payload: cms,
  })
  export const updateTransactionsFail = error => ({
    type: UPDATE_TRANSACTIONS_FAIL,
    payload: error,
  })
