import React, { useEffect, useRef, useState } from "react"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    Label,
} from "reactstrap"
//Import Breadcrumb
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../helpers/api_helper"
import { getCms } from "../store/actions"

const TermsAndCondition = props => {
    const {cms} = useSelector((state) => state.Cms )

    const dispatch = useDispatch();
    const [data, setData] = useState('')
    //meta title
    document.title = "Terms & Conditions"

    useEffect(() => {
        
        dispatch(getCms())
        console.log(cms);
    }, [])
    useEffect(()=>{
        if(cms){
            setData(cms?.cmsData?.webterms)
        }
    },[cms])
 
    return (
        <React.Fragment>
            <div className="" style={{'padding':'0px !important'}}>
                <Container fluid={true} className="px-0">
                <header className="p-4 position-fixed w-100 text-center shadow-lg bg-light" style={{'zIndex':'99999','Background' :'white !important'}}>
                    <h3 className="page_heading new-page-heading">Terms & Conditions</h3>
                </header>

                    <div className="row p-5">
                        <div className="col-md-12 p-3" style={{'border' : '2px solid grey','borderRadius' : '5px','marginTop':'88px'}}>
                        <div dangerouslySetInnerHTML={{ __html: cms?.cmsData?.webterms }} />
                        </div>
                       
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default TermsAndCondition
