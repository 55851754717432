import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {useHistory } from "react-router-dom";
import { map } from "lodash";
import { Card, CardBody, Col, Container, Form, Label, Modal, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { setTokenHeader } from "../../../helpers/api_helper";
import { startLoader } from "../../../store/loaderState/actions";
import { useDispatch, useSelector } from "react-redux";
import ProviderCard from "./ProviderCard";
import { Field,  FormikProvider, useFormik } from "formik";
import { getNotVerifiedProviders, updateProviders } from "../../../store/providers/actions";
import "../../../assets/scss/custom/custom.css"

const NewProviders = () => {
    const { providers } = useSelector(state => state.Providers)
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const handleClose = () => setModal(!modal);

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
        dispatch(startLoader());
        dispatch(getNotVerifiedProviders())

        console.log(providers);
    }, []);

    const tog_large = (id) => {
        console.log('id : ', id);
        setData({ ...data, defaultProviderId: id })
        setModal(!modal);

        removeBodyCss()
    }
    const removeBodyCss = () => {
        document.body.classList.add("no_padding")
    }
    const [data, setData] = useState({

        isVerified: true,
        sessionRate: '',
        defaultProviderId: '',
    })
 

    const formik = useFormik({
        initialValues: data,
        onSubmit: async (values) => {
           

        },
    });

    const HandleSubmit = () => {
        if(data.sessionRate == ''){
            alert('Please Add Session Rate.');
        }else{
            console.log(data);
            handleClose();
        dispatch(startLoader());

            dispatch(updateProviders(data.defaultProviderId,
                data
                , history))
        }
    }


    return (
        <FormikProvider value={formik}>
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Instructor" breadcrumbItem="New Instructor" />
                    {providers?.list?.length > 0 ? (
                        <Row>
                        {map(providers.list, (provider, contactkey) => (
                            <ProviderCard provider={provider} key={"_providers_" + contactkey} tog_large={tog_large} />
                        ))}
                        </Row>
                    ) : (

                     <Row>
                        <Col xs="12">
                            <div className="text-center my-3 card p-5">
                                {/* <Link to="#" className="text-success">
                                    <i className="bx bx-hourglass bx-spin me-2" />
                                    Load more
                                </Link> */}
                                <p className="h3">No Request Found</p>
                            </div>
                        </Col>
                    </Row>
                    )
                    }
                   

                </Container>
            </div>
            <form  onSubmit={formik.handleSubmit}>
                <Modal
                size="lg"
                isOpen={modal}
                toggle={tog_large}>
                <div>
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Session Rate for Provider
                        </h5>
                        <button
                            onClick={handleClose}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        
                            <Row>
                                <Col xl={12}>   
                                    <Card>
                                        <CardBody>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">
                                                        Session Rate
                                                    </Label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="sessionRate"
                                                        name="sessionRate"
                                                        onChange={e => {
                                                            setData({ ...data, sessionRate: e.target.value })
                                                        }}
                                                        value={data.sessionRate}
                                                        
                                                    />

                                                </div>
                                                <div className="text-center">
                                                    <div className="w-50 mx-auto">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-outline-primary"
                                                            onClick={HandleSubmit}
                                                        >
                                                            Approve
                                                        </button>
                                                    </div>
                                                </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    </div>
                </div>
            </Modal>
            </form>
        </React.Fragment>
        </FormikProvider>
    );
}

NewProviders.propTypes = {
    projects: PropTypes.array,
};

export default NewProviders;
