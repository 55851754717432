import React, { useEffect, useState } from "react"
import { cilFilter, cilPencil, cilTrash } from "@coreui/icons"

import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import {
    Row,
    Col,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import { startLoader } from "../../../store/loaderState/actions";
import { getTransactions, getTransactionsSuccess, getUserTransactions } from "../../../store/transactions/actions"
import { Image } from "react-bootstrap"
import moment from "moment"
import axios from "axios"
import SelectAsyncPaginate from "../classes/SelectAsyncPaginate"
import DateRangePicker from "react-bootstrap-daterangepicker"
import { LoadInstructors, LoadUsers } from "../../../components/Common/dropDownApis"

const UserTransaction = () => {
    document.title = "User Transactions";
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const dispatch = useDispatch();
    const history = useHistory();
    const { transactions } = useSelector(state => state.Transactions)
    const [showFilter, setShowFilter] = useState(true)
    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        'userId': [],
        'startDate': null,
        "endDate": null,
        "limit": 10,
        
    })
    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
        dispatch(startLoader());
        dispatch(getUserTransactions())
        console.log(transactions);

        return () => {
            dispatch(getTransactionsSuccess({}))
        }
    }, []);

    useEffect(() => {
        console.log(filterData)
        // dispatch(startLoader());
        dispatch(getUserTransactions(filterData))
    }, [filterData]);

    useEffect(() => {
            console.log('transactions : ' , transactions)
    }, [transactions]);

    const handleFilterState = (key, value) => {
        console.log(value)
        setFilterData((prev) => ({ ...prev, [key]: value }))
    }
    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const customSort = (rows, selector, direction) => {
        console.log(rows, selector)
        handleMultiChange({
            'sortBy': rows.actualKey,
            "order": selector == 'desc' ? -1 : 1
        })
    };
    const ResetFilters = () => {


        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            'userId': [],
            'startDate': null,
            "endDate": null,
            "limit": 10
        })
        // setFilterData({})

    }
    const handleSelect = (event, picker) => {
        setFilterData({
            startDate: picker.startDate.toISOString(),
            endDate: picker.endDate.toISOString(),
        });
    };

    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                if ((transactions?.pagination?.page - 1) != 0) {

                    return ((transactions?.pagination?.page - 1) * filterData.limit) + (index + 1)
                }
                return index + 1
            },
            sortable: true,
        },
       
        {
            name: "Name",
            selector: row => `${row.userId?.fname} ${row.userId?.lname}`,
            sortable: true,
            actualKey:"userId.fname"
        },

        {
            name: "Email",
            selector: row => row.userId?.email,
            sortable: true,
            actualKey:"userId.email"
        },
        {
            name: "Amount",
            selector: row => `$${row.amount}`,
            sortable: true,
            actualKey:"amount"
        },
        {
            name: "Date",
            selector: row => moment(row.orderId?.createdAt).format('MM/DD/YYYY'),
            sortable: true,
            actualKey:"createdAt"
        },
        {
            name: "Message",
            selector: row => row.note ? row.note : 'N/A',
            sortable: false,
        },


    ]

    return (
        <React.Fragment>
            <div className="page-content cast ">
                <div className="container-fluid">
                    <Breadcrumbs title="Transactions" breadcrumbItem="User Transactions" />
                    <Row>
                        <Col xl={12}>
                            <CCardGroup className="mb-2">
                                <CCard>
                                    <CCardBody>
                                        <div className="row">
                                            <div className="col-md-6 mb-2 position-relative " >
                                                <label>Select User:</label>
                                                <SelectAsyncPaginate
                                                    name="userId"
                                                    // regionName={region.value}
                                                    loadOptions={LoadUsers}
                                                    label={"Users"}
                                                    isMulti={true}
                                                    value={filterData?.userId}
                                                    onChange={(userId) => { handleFilterState('userId', userId) }}
                                                />
                                            </div>
                                            <div className="col-md-6 ">
                                                <div className="w-100">
                                                    <label>Search :</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="search"
                                                        name="search"
                                                        placeholder="Search"
                                                        value={filterData?.search}
                                                        onChange={e => {
                                                            handleFilterState('search', e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className="w-25 d-none mt-4 d-flex justify-content-center align-items-center">
                                                    <button className="border text-success p-2" onClick={() => {
                                                        setShowFilter(prevShowFilter => !prevShowFilter);
                                                    }}
                                                        style={{
                                                            'cursor': 'pointer', 'border': '1px solid #556ee6', 'padding': '6px',
                                                            'borderRadius': '3px'
                                                        }}

                                                    >
                                                        <label className="mb-0">More Filters</label>

                                                        <CIcon
                                                            icon={cilFilter}
                                                            className="text-primary hand searchFilter"
                                                            style={{ 'height': '15px !important', 'cursor': 'pointer' }}
                                                            title="View Detail"

                                                        />
                                                    </button>

                                                </div>
                                            </div>

                                        </div>
                                        <div className={`row mt-2 ${showFilter == true ? '' : 'd-none'}`}>


                                            <div className="col-md-6">
                                                <label>Select Date:</label>
                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        type="date"
                                                        className="form-control"
                                                        id="date"
                                                        name="date"
                                                        onChange={newValue => {
                                                             handleFilterState('date',newValue.$d.toISOString())
                                                           
                                                        }}
                                                    />
                                                </LocalizationProvider> */}
                                                <div>
                                                    <DateRangePicker
                                                        startDate={filterData.startDate}
                                                        endDate={filterData.endDate}
                                                        onApply={handleSelect}
                                                    >
                                                        <input type="text" className="form-control"
                                                            placeholder="Please select a date"
                                                        />
                                                    </DateRangePicker>
                                                </div>
                                            </div>




                                            <div className="col-md-12 mt-2">
                                                <input
                                                    type="button"
                                                    className="btn btn-primary"
                                                    id="search"
                                                    value="Reset"
                                                    onClick={() => ResetFilters()}
                                                />
                                            </div>
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                        </Col>

                        <Col xl={12}>
                            <CCardGroup>
                                <CCard>
                                    <CCardBody>
                                        <DataTable
                                            striped
                                            onSort={customSort}
                                            columns={columns}
                                            data={transactions?.data}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            keyField={'keyField'}
                                            paginationTotalRows={transactions?.pagination?.total}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={(page, totalRows) => {;
                                                handleFilterState('page', page)
                                            }}
                                        />
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>

                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UserTransaction
