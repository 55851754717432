/** Get Dashboard Chart data */
export const GET_CMS = "GET_CMS";
export const GET_CMS_SUCCESS = "GET_CMS_SUCCESS";
export const GET_CMS_FAIL = "GET_CMS_FAIL";

export const ADD_CMS = "ADD_CMS";
export const ADD_CMS_SUCCESS = "ADD_CMS_SUCCESS";
export const ADD_CMS_FAIL = "ADD_CMS_FAIL";

export const DELETE_CMS = "DELETE_CMS";
export const DELETE_CMS_SUCCESS = "DELETE_CMS_SUCCESS";
export const DELETE_CMS_FAIL = "DELETE_CMS_FAIL";

export const EDIT_CMS = "EDIT_CMS";
export const EDIT_CMS_SUCCESS = "EDIT_CMS_SUCCESS";
export const EDIT_CMS_FAIL = "EDIT_CMS_FAIL";

export const UPDATE_CMS = "UPDATE_CMS"
export const UPDATE_CMS_SUCCESS = "UPDATE_CMS_SUCCESS"
export const UPDATE_CMS_FAIL = "UPDATE_CMS_FAIL"