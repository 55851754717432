import React, { useEffect, useRef, useState } from "react"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    Label,
} from "reactstrap"
//Import Breadcrumb
import { setTokenHeader } from "../../../helpers/api_helper";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./style.css"
import { useDispatch, useSelector } from "react-redux"
import { getCms, updateCms } from "../../../store/cms/actions"
import { Link } from "react-router-dom";

const PrivacyPoliciesWeb = props => {
    const {cms} = useSelector((state) => state.Cms )

    const dispatch = useDispatch();
    const [data, setData] = useState('')
    //meta title
    document.title = "Website Privacy Policy "

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
        dispatch(getCms())
        console.log(cms);
    }, [])
    useEffect(()=>{
        if(cms){
            setData(cms?.cmsData?.webpolicy)
        }
    },[cms])
    const HandleSubmit = () => {
        console.log(data)
        const obj = {
            'webpolicy':data
        }
        console.log(obj)
        dispatch(updateCms(obj))
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <div class="row mx-0">
                        <div class="col-md-6 pl-0 pb-2">
                            <h5 class="page_heading new-page-heading">Website Privacy Policy </h5>
                            <Link to={'/privacyPolicy'} className="btn btn-primary w-md" target="_blank">View</Link>
                        </div>
                        
                    </div>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                                <div className="mb-3">
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={cms?.cmsData?.webpolicy}
                                                        onReady={editor => {
                                                            // You can store the "editor" and use when it is needed.
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const editedData = editor.getData();
                                                            console.log({editedData});
                                                            console.log(data);
                                                            setData(editedData)
                                                        }}
                                                        onBlur={(event, editor) => {
                                                        }}
                                                        onFocus={(event, editor) => {
                                                        }}
                                                    />
                                                </div>

                                                <div className="pt-5">
                                                    <button
                                                        type="submit"
                                                        onClick={()=>{
                                                            HandleSubmit()
                                                        }}
                                                        className="btn btn-primary w-md"
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    )
}


export default PrivacyPoliciesWeb
