import {
    GET_INCIDENTS,
    GET_INCIDENTS_SUCCESS,
    GET_INCIDENTS_FAIL,
    ADD_INCIDENTS,
    ADD_INCIDENTS_SUCCESS,
    ADD_INCIDENTS_FAIL,
   UPDATE_INCIDENTS,
   UPDATE_INCIDENTS_SUCCESS,
   UPDATE_INCIDENTS_FAIL,
   DELETE_INCIDENTS,
   DELETE_INCIDENTS_SUCCESS,
   DELETE_INCIDENTS_FAIL,
   EDIT_INCIDENTS_SUCCESS,
   EDIT_INCIDENTS,
   EDIT_INCIDENTS_FAIL,
   GET_REPORTEDINCIDENTS,
   GET_REPORTEDINCIDENTS_SUCCESS,
   GET_REPORTEDINCIDENTS_FAIL
} from "./actionTypes";

export const getIncidents = (page = 1,searchKeyword = '') => ({
    type: GET_INCIDENTS,
    payload: { page , searchKeyword},
});

export const getIncidentsSuccess = (data) => ({
   
    type: GET_INCIDENTS_SUCCESS,
    payload: { data },
});

export const getIncidentsFail = (error) => ({
    type: GET_INCIDENTS_FAIL,
    payload: { error },
});

export const getReportedIncidents = (page = 1) => ({
    type: GET_REPORTEDINCIDENTS,
    payload: { page },
});

export const getReportedIncidentsSuccess = (data) => ({
   
    type: GET_REPORTEDINCIDENTS_SUCCESS,
    payload: { data },
});

export const getReportedIncidentsFail = (error) => ({
    type: GET_REPORTEDINCIDENTS_FAIL,
    payload: { error },
});



export const addIncidents = (data,history) => ({
    type: ADD_INCIDENTS,
    payload: { data ,history},
});

export const addIncidentsSuccess = (data) => ({
   
    type: ADD_INCIDENTS_SUCCESS,
    payload: { data },
});

export const addIncidentsFail = (error) => ({
    type: ADD_INCIDENTS_FAIL,
    payload: { error },
});

export const deleteIncidents = (id,history) => ({
    type: DELETE_INCIDENTS,
    payload: { id ,history},
});

export const deleteIncidentsSuccess = (data) => ({
   
    type: DELETE_INCIDENTS_SUCCESS,
    payload: { data },
});

export const deleteIncidentsFail = (error) => ({
    type: DELETE_INCIDENTS_FAIL,
    payload: { error },
});
export const editIncidents = (id) => ({
    type: EDIT_INCIDENTS,
    payload: { id},
});

export const editIncidentsSuccess = (data) => ({
   
    type: EDIT_INCIDENTS_SUCCESS,
    payload: { data },
});

export const editIncidentsFail = (error) => ({
    type: EDIT_INCIDENTS_FAIL,
    payload: { error },
});

export const updateIncidentsSuccess = incidents => ({
    type: UPDATE_INCIDENTS_SUCCESS,
    payload: incidents,
  })
  
  export const updateIncidents = (id , incidents,history) => ({
    type: UPDATE_INCIDENTS,
    payload: {id , incidents,history},
  })
  export const updateIncidentsFail = error => ({
    type: UPDATE_INCIDENTS_FAIL,
    payload: error,
  })
