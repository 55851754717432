import {
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAIL,
    ADD_CATEGORIES_SUCCESS,
    ADD_CATEGORIES_FAIL,
    DELETE_CATEGORIES_SUCCESS,
    DELETE_CATEGORIES_FAIL,
    EDIT_CATEGORIES_FAIL,
    EDIT_CATEGORIES_SUCCESS,
    UPDATE_CATEGORIES_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    categories: {},
    error: {},
    singleCategories: {}
};

const Categories = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload.data
            };
        case GET_CATEGORIES_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case ADD_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    data: [...state.categories.data, action.payload]
                },
            };
        case ADD_CATEGORIES_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case EDIT_CATEGORIES_SUCCESS:
            console.log('EDIT categories ====> ', action.payload.data.data)
            return {
                ...state,
                singleCategories: action.payload.data.data
            };
        case EDIT_CATEGORIES_FAIL:
            return {
                ...state,
                error: action.payload
            };
        

      case UPDATE_CATEGORIES_SUCCESS:

          return {
            ...state,
            categories: {
              ...state.data,
              data: state.categories.data.map(item => item?.id == action.payload.id ? action.payload : item)
            },
          }

        case DELETE_CATEGORIES_SUCCESS:
            console.log('DELETE categories ====> ', action.payload.data)
            return {
                ...state,
                categories: {
                    ...state.categories,
                    data: [...state.categories.data.filter(item => item['id'] !== action.payload.data.id)],
                    pagination:{...state.categories.pagination ,total:state.categories.pagination.total -1}
                },
            };
        case DELETE_CATEGORIES_FAIL:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }

}


export default Categories;