
export const GET_PROVIDERS = "GET_PROVIDERS";
export const GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS";
export const GET_PROVIDERS_FAIL = "GET_PROVIDERS_FAIL";

export const GET_NOT_VERIFIED_PROVIDERS = "GET_NOT_VERIFIED_PROVIDERS";
export const GET_NOT_VERIFIED_PROVIDERS_SUCCESS = "GET_NOT_VERIFIED_PROVIDERS_SUCCESS";
export const GET_NOT_VERIFIED_PROVIDERS_FAIL = "GET_NOT_VERIFIED_PROVIDERS_FAIL";

export const ADD_PROVIDERS = "ADD_PROVIDERS";
export const ADD_PROVIDERS_SUCCESS = "ADD_PROVIDERS_SUCCESS";
export const ADD_PROVIDERS_FAIL = "ADD_PROVIDERS_FAIL";

export const DELETE_PROVIDERS = "DELETE_PROVIDERS";
export const DELETE_PROVIDERS_SUCCESS = "DELETE_PROVIDERS_SUCCESS";
export const DELETE_PROVIDERS_FAIL = "DELETE_PROVIDERS_FAIL";

export const EDIT_PROVIDERS = "EDIT_PROVIDERS";
export const EDIT_PROVIDERS_SUCCESS = "EDIT_PROVIDERS_SUCCESS";
export const EDIT_PROVIDERS_FAIL = "EDIT_PROVIDERS_FAIL";

export const GET_PROVIDERS_DETAIL = "GET_PROVIDERS_DETAIL";
export const GET_PROVIDERS_DETAIL_SUCCESS = "GET_PROVIDERS_DETAIL_SUCCESS";
export const GET_PROVIDERS_DETAIL_FAIL = "GET_PROVIDERS_DETAIL_FAIL";

export const SETTLE_PROVIDER_PAYMENT = "SETTLE_PROVIDER_PAYMENT";
export const SETTLE_PROVIDER_PAYMENT_SUCCESS = "SETTLE_PROVIDER_PAYMENT_SUCCESS";
export const SETTLE_PROVIDER_PAYMENT_FAIL = "SETTLE_PROVIDER_PAYMENT_FAIL";


export const UPDATE_PROVIDERS = "UPDATE_PROVIDERS"
export const UPDATE_PROVIDERS_SUCCESS = "UPDATE_PROVIDERS_SUCCESS"
export const UPDATE_PROVIDERS_FAIL = "UPDATE_PROVIDERS_FAIL"