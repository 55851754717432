import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
} from "reactstrap"
import NoImgFound from "../../../assets/No-image-found.jpg"


const ProviderCard = (props) =>{
  
    const provider = props.provider;
    return (
      <React.Fragment>
        <Col xl="3" sm="6">
          <Card className="text-center">
            <CardBody>
                <div className="mb-4">
                  <img
                    className="rounded-circle avatar-sm"
                    src={provider.userProfile != '' ? process.env.REACT_APP_ASSET_URL+provider.userProfile : NoImgFound}
                    alt=""
                  />
                </div>

              <h5 className="font-size-15 mb-1">
                <Link to="#" className="text-dark">
                  {provider.fname+ ' '+ provider.lname}
                </Link>
              </h5>
              <p className="text-muted">{provider.email}</p>
              <p className="text-muted">{provider.countryCode } {provider.phone}</p>
            </CardBody>
            <CardFooter className="bg-transparent border-top">
              <div className="row">
                <div className="col-md-6">
                <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      props.tog_large(provider._id)
                    }}
                >
                    Approve
                </button>
                </div>
                <div className="col-md-6">
                </div>
              </div>

            </CardFooter>
          </Card>
        </Col>
      </React.Fragment>
    )
  }

ProviderCard.propTypes = {
  provider: PropTypes.object
}

export default ProviderCard
