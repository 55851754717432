import {
    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAIL,
    ADD_CATEGORIES,
    ADD_CATEGORIES_SUCCESS,
    ADD_CATEGORIES_FAIL,
   UPDATE_CATEGORIES,
   UPDATE_CATEGORIES_SUCCESS,
   UPDATE_CATEGORIES_FAIL,
   DELETE_CATEGORIES,
   DELETE_CATEGORIES_SUCCESS,
   DELETE_CATEGORIES_FAIL,
   EDIT_CATEGORIES_SUCCESS,
   EDIT_CATEGORIES,
   EDIT_CATEGORIES_FAIL
} from "./actionTypes";

export const getCategories = (page = 1) => ({
    type: GET_CATEGORIES,
    payload: { page },
});

export const getCategoriesSuccess = (data) => ({
   
    type: GET_CATEGORIES_SUCCESS,
    payload: { data },
});

export const getCategoriesFail = (error) => ({
    type: GET_CATEGORIES_FAIL,
    payload: { error },
});


export const addCategories = (data,history) => ({
    type: ADD_CATEGORIES,
    payload: { data ,history},
});

export const addCategoriesSuccess = (data) => ({
   
    type: ADD_CATEGORIES_SUCCESS,
    payload: { data },
});

export const addCategoriesFail = (error) => ({
    type: ADD_CATEGORIES_FAIL,
    payload: { error },
});

export const deleteCategories = (id,history) => ({
    type: DELETE_CATEGORIES,
    payload: { id ,history},
});

export const deleteCategoriesSuccess = (data) => ({
   
    type: DELETE_CATEGORIES_SUCCESS,
    payload: { data },
});

export const deleteCategoriesFail = (error) => ({
    type: DELETE_CATEGORIES_FAIL,
    payload: { error },
});
export const editCategories = (id) => ({
    type: EDIT_CATEGORIES,
    payload: { id},
});

export const editCategoriesSuccess = (data) => ({
   
    type: EDIT_CATEGORIES_SUCCESS,
    payload: { data },
});

export const editCategoriesFail = (error) => ({
    type: EDIT_CATEGORIES_FAIL,
    payload: { error },
});

export const updateCategoriesSuccess = categories => ({
    type: UPDATE_CATEGORIES_SUCCESS,
    payload: categories,
  })
  
  export const updateCategories = (id,data,history) => ({
    type: UPDATE_CATEGORIES,
    payload: {id , data,history},
  })
  export const updateCategoriesFail = error => ({
    type: UPDATE_CATEGORIES_FAIL,
    payload: error,
  })
