import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_CATEGORIES, ADD_CATEGORIES, DELETE_CATEGORIES, EDIT_CATEGORIES, UPDATE_CATEGORIES } from "./actionTypes";
import { getCategoriesFail, getCategoriesSuccess, addCategoriesFail, addCategoriesSuccess, deleteCategoriesSuccess, deleteCategoriesFail, editCategoriesSuccess, editCategoriesFail, updateCategoriesSuccess, updateCategoriesFail } from "./actions";

//Include Both Helper File with needed methods
import {
    getCategoriesApi,
    addNewCategoriesApi,
    deleteCategoriesApi,
    editCategoriesApi,
    updateCategoriesApi
}
    from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { stopLoader } from "../loaderState/actions";

function* getCategoriesData({ payload: page }) {
    try {

        let response = yield call(getCategoriesApi, page.page);
        yield put(getCategoriesSuccess(response));
    } catch (error) {
        yield put(getCategoriesFail(error));
    }
    yield put(stopLoader())

}

function* addCategoriesData({ payload: { data, history } }) {
    try {

        let response = yield call(addNewCategoriesApi, data);
        // yield put(getCategoriesSuccess(response));
        console.log('response : ' ,response)
        if(response.status ==200){
            history.push('/all-categories');
        }else{
            toast.error(response.message)
        }

    } catch (error) {
        yield put(getCategoriesFail(error));
    }
    yield put(stopLoader())

}


function* editCategories({ payload: id }) {
    try {

        let response = yield call(editCategoriesApi, id.id);
        console.log(response);
        yield put(editCategoriesSuccess(response));
    } catch (error) {
        yield put(editCategoriesFail(error));
    }
    yield put(stopLoader())

}

function* onUpdateCategories({ payload: { id, data, history } }) {
    try {
        const response = yield call(updateCategoriesApi, id, data)
        if(response.status ==200){
            history.push('/all-categories');
        }else{
            toast.error(response.message)
        }
    } catch (error) {
        yield put(updateCategoriesFail(error))
    }
    yield put(stopLoader())

}

function* deleteCategories({ payload: id }) {
    try {
        const response = yield call(deleteCategoriesApi, id.id)
        if(response.status ==201){
        yield put(deleteCategoriesSuccess(id))
        // yield put(getCategories());
        }else{
            toast.error(response.message)
        }

    } catch (error) {
        yield put(deleteCategoriesFail(error))
    }
    yield put(stopLoader())

}

function* categoriesSaga() {
    yield takeEvery(GET_CATEGORIES, getCategoriesData);
    yield takeEvery(ADD_CATEGORIES, addCategoriesData);
    yield takeEvery(EDIT_CATEGORIES, editCategories);
    yield takeEvery(UPDATE_CATEGORIES, onUpdateCategories)
    yield takeEvery(DELETE_CATEGORIES, deleteCategories);
}


export default categoriesSaga;
