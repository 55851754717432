import {
    GET_CMS,
    GET_CMS_SUCCESS,
    GET_CMS_FAIL,
    ADD_CMS,
    ADD_CMS_SUCCESS,
    ADD_CMS_FAIL,
   UPDATE_CMS,
   UPDATE_CMS_SUCCESS,
   UPDATE_CMS_FAIL,
   DELETE_CMS,
   DELETE_CMS_SUCCESS,
   DELETE_CMS_FAIL,
   EDIT_CMS_SUCCESS,
   EDIT_CMS,
   EDIT_CMS_FAIL
} from "./actionTypes";

export const getCms = (page = 1) => ({
    type: GET_CMS,
    payload: { page },
});

export const getCmsSuccess = (data) => ({
   
    type: GET_CMS_SUCCESS,
    payload: { data },
});

export const getCmsFail = (error) => ({
    type: GET_CMS_FAIL,
    payload: { error },
});


export const addCms = (data,history) => ({
    type: ADD_CMS,
    payload: { data ,history},
});

export const addCmsSuccess = (data) => ({
   
    type: ADD_CMS_SUCCESS,
    payload: { data },
});

export const addCmsFail = (error) => ({
    type: ADD_CMS_FAIL,
    payload: { error },
});

export const deleteCms = (id,history) => ({
    type: DELETE_CMS,
    payload: { id ,history},
});

export const deleteCmsSuccess = (data) => ({
   
    type: DELETE_CMS_SUCCESS,
    payload: { data },
});

export const deleteCmsFail = (error) => ({
    type: DELETE_CMS_FAIL,
    payload: { error },
});
export const editCms = (id) => ({
    type: EDIT_CMS,
    payload: { id},
});

export const editCmsSuccess = (data) => ({
   
    type: EDIT_CMS_SUCCESS,
    payload: { data },
});

export const editCmsFail = (error) => ({
    type: EDIT_CMS_FAIL,
    payload: { error },
});

export const updateCmsSuccess = cms => ({
    type: UPDATE_CMS_SUCCESS,
    payload: cms,
  })
  
  export const updateCms = (cms) => ({
    type: UPDATE_CMS,
    payload: cms,
  })
  export const updateCmsFail = error => ({
    type: UPDATE_CMS_FAIL,
    payload: error,
  })
