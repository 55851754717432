/** Get Dashboard Chart data */
export const GET_SPLASH_SCREEN = "GET_SPLASH_SCREEN";
export const GET_SPLASH_SCREEN_SUCCESS = "GET_SPLASH_SCREEN_SUCCESS";
export const GET_SPLASH_SCREEN_FAIL = "GET_SPLASH_SCREEN_FAIL";

export const ADD_SPLASH_SCREEN = "ADD_SPLASH_SCREEN";
export const ADD_SPLASH_SCREEN_SUCCESS = "ADD_SPLASH_SCREEN_SUCCESS";
export const ADD_SPLASH_SCREEN_FAIL = "ADD_SPLASH_SCREEN_FAIL";

export const DELETE_SPLASH_SCREEN = "DELETE_SPLASH_SCREEN";
export const DELETE_SPLASH_SCREEN_SUCCESS = "DELETE_SPLASH_SCREEN_SUCCESS";
export const DELETE_SPLASH_SCREEN_FAIL = "DELETE_SPLASH_SCREEN_FAIL";

export const EDIT_SPLASH_SCREEN = "EDIT_SPLASH_SCREEN";
export const EDIT_SPLASH_SCREEN_SUCCESS = "EDIT_SPLASH_SCREEN_SUCCESS";
export const EDIT_SPLASH_SCREEN_FAIL = "EDIT_SPLASH_SCREEN_FAIL";

export const UPDATE_SPLASH_SCREEN = "UPDATE_SPLASH_SCREEN"
export const UPDATE_SPLASH_SCREEN_SUCCESS = "UPDATE_SPLASH_SCREEN_SUCCESS"
export const UPDATE_SPLASH_SCREEN_FAIL = "UPDATE_SPLASH_SCREEN_FAIL"